import React, { useState, useEffect, useRef } from "react";
import Webheader from "../../components/common/Webheader";
import "./Web.css";
import logo1 from "../../assets/images/brands/1.png";
import logo2 from "../../assets/images/brands/2.png";
import logo3 from "../../assets/images/brands/3.png";
import logo4 from "../../assets/images/brands/4.png";
import logo5 from "../../assets/images/brands/5.png";
import logo6 from "../../assets/images/brands/6.png";
import logo7 from "../../assets/images/brands/7.png";
import logo8 from "../../assets/images/brands/8.png";
import logo9 from "../../assets/images/brands/9.png";
import WebFooter from "../../components/common/WebFooter";
import favicon from '../../assets/images/favicon.png';
import AOS from "aos";
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

const WebHome = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    document.title = "Kevin India"
    const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = favicon; // Replace 'new-favicon-url.png' with your new favicon URL
        document.head.appendChild(newFavicon);
        newFavicon.href = favicon;
  }, []);
  return (
    <div className="webmainbg">
      <Webheader />
      <div className="section section-one">
        <div className="pagewidth">
          <div className="row">
            <div className="col-sm-7">
              <div className="webheadleftdiv">
                <div className="webheadleftdiv1">
                  <p className="discoverhead " data-aos="fade-up">
                    Discover the <br /> World of Colors
                  </p>
                  <div className="section1bg deskhide tablethide"></div>
                  <p className="findperfect" data-aos="fade-up" data-aos-delay="500">
                    Find the Perfect Shade for Your Needs
                  </p>
                  <div className="headbtns-div" data-aos="fade-up" data-aos-delay="1000">
                    <Link to={'/find-product/all'} className="button-div">
                      <p>Try Now</p>
                    </Link>
                    <Link to={'/products'} className="button-div adjustleft">
                      <p>Explore</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="section1bg mobhide tabletshow"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-two">
        <div className="pagewidth">
          <p className="brandhead">Kevin Group's Brands</p>
          <div className="brandsdiv">
            <img src={logo1} className="brandslogo" data-aos="fade-up" />
            <img src={logo2} className="brandslogo" data-aos="fade-up" data-aos-delay="150" />
            <img src={logo3} className="brandslogo" data-aos="fade-up" data-aos-delay="300" />
            <img src={logo4} className="brandslogo" data-aos="fade-up" data-aos-delay="450" />
            <img src={logo5} className="brandslogo" data-aos="fade-up" data-aos-delay="600" />
            <img src={logo6} className="brandslogo" data-aos="fade-up" data-aos-delay="750" />
            <img src={logo7} className="brandslogo" data-aos="fade-up" data-aos-delay="900" />
            <img src={logo8} className="brandslogo" data-aos="fade-up" data-aos-delay="1050" />
            <img src={logo9} className="brandslogo" data-aos="fade-up" data-aos-delay="1200" />
          </div>
        </div>
      </div>
      <div className="section section-three">
        <div className="pagewidth">
          <div className="sectionthreediv">
            <div className="sectionthreedivbox">
              <div className="row">
                <div className="col-sm-7" data-aos="fade-up" data-aos-delay="500">
                  <div className="section3leftdiv">
                    <p className="section3head">
                      Introducing Our Extensive <br /> Range of Colours!
                    </p>
                    <div className="section3bg deskhide tablethide"></div>
                    <p className="section3subtext" >
                      Take a Look at Our Product Lists!
                    </p>
                    <div className="headbtns-div" >
                      <Link to={'/products'} className="button-div">
                        <p>View</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="section3bg mobhide tabletshow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-four">
        <div className="pagewidth">
          <div className="row">
            <div className="col-sm-6">
              <div className="section4img mobhide tabletshow"></div>
            </div>
            <div className="col-sm-6" data-aos="fade-up" data-aos-delay="500">
              <div className="section4rightdiv">
                <p className="section4head"  >Colour Matching</p>
                <div className="section4img deskhide tablethide"></div>
                <p className="section4description" >
                  Masters of color, blending artistry and precision. With
                  decades of cross-industry experience, we bring visions to
                  life, from historical palettes to modern aesthetics. Fueled by
                  passion, we innovate fearlessly, using the latest tech and
                  trends. Join us in experiencing impactful visual narratives
                  through the language of color.
                </p>
                <div className="headbtns-div">
                  <Link to={'/Contact'} className="button-div">
                    <p>Get Started</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-five">
        <div className="pagewidth">
          <div className="row">
            <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div className="section5leftdiv">
                <p className="section5subhead" >Features of the Kevin Process</p>
                <p className="section4head" >Why Choose Kevin?</p>
                <div className="section5rightbg deskhide">
                  <iframe width="100%" height="300" src="https://www.youtube.com/embed/fT329ioGl8I" frameborder="0" allowfullscreen></iframe>
                </div>
                <p className="section4description section5description" >
                  Collaborating with us, a firm deeply entrenched in backward
                  integration within the pigments and dyes sector, alongside a
                  robust dedication to advanced research and development,
                  guarantees a steadfast supply of uniform, premium-grade
                  products. This strategic alignment facilitates bespoke
                  customization, leverages specialized technical acumen, and
                  adeptly navigates dynamic market trends, thereby fostering a
                  resilient and enduring partnership.
                </p>
                <div className="headbtns-div" >
                  <Link to={'/about-us'} className="button-div">
                    <p>About Us</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="section5rightbg mobhide">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/fT329ioGl8I" frameborder="0" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-six">
        <div className="pagewidth">
          <div className="section6box">
            <div className="row">
              <div className="col-sm-6 col-md-7">
                <div className="section6leftdiv">
                  <div className="section6imgbg mobhide tabletshow"></div>
                  <p className="section3head" data-aos="fade-up" data-aos-delay="0">
                    Join Our Global &nbsp;
                    <br />
                    Community
                  </p>
                  <div className="section6imgbg deskhide tablethide"></div>
                  <p className="section3subtext" data-aos="fade-up" data-aos-delay="300">
                    Unlock the magic of colours in 3 Simple Steps
                  </p>
                  <div className="mobhide tabletshow">
                    <div className="headbtns-div" data-aos="fade-up" data-aos-delay="600">
                      <Link to={'/Contact'} className="button-div button-div1">
                        <p>Contact Us Now</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-5">
                <div className="section6rightdiv">
                  <div className="setpsdiv" data-aos="fade-up" data-aos-delay="0">
                    <p className="section4head section6headsteps">Step 1</p>
                    <p className="section3subtext section6descsteps">
                      Connect with Our Experts
                    </p>
                  </div>
                  <div className="setpsdiv setpsdiv2" data-aos="fade-up" data-aos-delay="300">
                    <p className="section4head section6headsteps">Step 2</p>
                    <p className="section3subtext section6descsteps">
                      Our experts will create a curated offer
                      <br /> tailored to your specific needs.
                    </p>
                  </div>
                  <div className="setpsdiv2" data-aos="fade-up" data-aos-delay="600">
                    <p className="section4head section6headsteps">Step 3</p>
                    <p className="section3subtext section6descsteps">
                      Achieve Success with Kevin
                    </p>
                  </div>
                  <div className="deskhide tablethide">
                    <div className="headbtns-div">
                      <Link to={'/Contact'} className="button-div button-div1">
                        <p>Contact Us Now</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WebFooter />
    </div>
  );
};

export default WebHome;
