import React, {useState, useEffect, useMemo} from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";

import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule]);


const AdminRequestSample = () => {
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);

    const [rowData, setRowData] = useState([]);
                      const [colDefs, setColDefs] = useState([]);
                      const paginationPageSizeSelector = useMemo(() => {
                        return [10, 20, 50, 100];
                      }, []);
                
                      const defaultColDef = {
                        flex: 1,
                        resizable: true,
                        sortable: true 
                      };
            
                      const clickhandle = () =>  {
                        // console.log('Mission Launched')
                      }

    const fetchrequestsampledata = async() => {
        setshowloding(true);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'adminfetchrfs', headers).then((res) => {
            // console.log('res >>>', res.data)
               if(res.data.status == 'success') {
                              setrequestsampledata(res.data.data);
                              // dispatch({ type: "userdata", payload: res.data.data })
                              //  navigate('/cms/customer/requestsample');
                              var prevdata = res.data.data;
                                                                  if(prevdata?.length > 0) {
                                                                      var columnsarr = [];
                                                                      var hidecolumns = [
                                                                          "id",
                                                                          "customerid",
                                                                          "quantity",
                                                                          "dispatchdate",
                                                                          "status",
                                                                          "document",
                                                                          "adminsamples",
                                                                          "comment",
                                                                          "revisedocument",
                                                                          "feedbackdocument",
                                                                          "note",
                                                                          "samplesarr",
                                                                          "created_at",
                                                                          "updated_at",
                                                                         
                                                                      ];
                                                                      var hidefilters = [];
                                                                      var getcolumns = Object.keys(prevdata[0]);
                                                                      console.log('getcolumns >>', getcolumns);
                                                                      var columns = [
                                                                          "id",
                                                                          "customerid",
                                                                          "productname",
                                                                          "quantity",
                                                                          "application",
                                                                          "referencenumber",
                                                                          "deliverystatus",
                                                                          "dispatchdate",
                                                                          "rfsnumber",
                                                                          "status",
                                                                          "document",
                                                                          "adminsamples",
                                                                          "comment",
                                                                          "revisedocument",
                                                                          "feedbackdocument",
                                                                          "note",
                                                                          "samplesarr",
                                                                          "created_at",
                                                                          "updated_at",
                                                                          "name"
                                                                      ]
                                                                      console.log('columns >>', columns);
                                                                      for(var i = 0; i < columns?.length;i++) {
                                                                          if(!hidecolumns.includes(columns[i])) {
                                                                            var obj = {};
                                                                            obj.field = columns[i];
                                                                            obj.headerName = columns[i] == 'productname' ?
                                                                            'Product Name' :
                                                                            columns[i] == 'deliverystatus' ? 'Delivery Status' :
                                                                            columns[i] == 'referencenumber' ? 'Ref. No.' :
                                                                            columns[i] == 'rfsnumber' ? 'RFS No.' :
                                                                            columns[i] == 'name' ? 'Customer Name'
                                                                            : null;
                                                                          //   obj.wrapText = true;
                                                                          //   obj.autoHeight = true;
                                                                          if (
                                                                            columns[i] ==
                                                                            "productname"
                                                                          ) {
                                                                            // obj.cellClass = 'pfinumberclass';
                                                                            obj.cellRenderer =
                                                                              (params) => {
                                                                                var item =
                                                                                  params?.data;
                                                                                var samplesarr =
                                                                                  JSON.parse(
                                                                                    item.samplesarr
                                                                                  );
                                                                                return (
                                                                                  <span
                                                                                    className="clickable-age"
                                                                                    // onClick={() => clickproductname(
                                                                                    //     params?.node?.rowIndex,
                                                                                    //   item.id,
                                                                                    //   item.status
                                                                                    // )}
                                                                                  >
                                                                                    <ol type="a">
                                                                                      {samplesarr?.map(
                                                                                        (
                                                                                          itm,
                                                                                          ind
                                                                                        ) => {
                                                                                          return (
                                                                                            <li
                                                                                              style={{
                                                                                                textAlign:
                                                                                                  "left",
                                                                                                paddingLeft: 5,
                                                                                              }}
                                                                                            >
                                                                                              {
                                                                                                itm?.productname
                                                                                              }
                                                                                            </li>
                                                                                          );
                                                                                        }
                                                                                      )}
                                                                                    </ol>
                                                                                  </span>
                                                                                );
                                                                              };
                                                                          }
                                                                          if (
                                                                              columns[i] ==
                                                                              "application"
                                                                            ) {
                                                                              // obj.cellClass = 'pfinumberclass';
                                                                              obj.cellRenderer =
                                                                                (params) => {
                                                                                  var item =
                                                                                    params?.data;
                                                                                  var samplesarr =
                                                                                    JSON.parse(
                                                                                      item.samplesarr
                                                                                    );
                                                                                  return (
                                                                                    <span
                                                                                      className="clickable-age"
                                                                                      // onClick={() => clickproductname(
                                                                                      //     params?.node?.rowIndex,
                                                                                      //   item.id,
                                                                                      //   item.status
                                                                                      // )}
                                                                                    >
                                                                                     {samplesarr[0]?.application}
                                                                                    </span>
                                                                                  );
                                                                                };
                                                                            }
                                                                            if (
                                                                              columns[i] ==
                                                                              "referencenumber"
                                                                            ) {
                                                                              // obj.cellClass = 'pfinumberclass';
                                                                              obj.cellRenderer =
                                                                                (params) => {
                                                                                  var item =
                                                                                    params?.data;
                                                                                  var samplesarr =
                                                                                    JSON.parse(
                                                                                      item.samplesarr
                                                                                    );
                                                                                  return (
                                                                                    <span
                                                                                      className="clickable-age"
                                                                                      // onClick={() => clickproductname(
                                                                                      //     params?.node?.rowIndex,
                                                                                      //   item.id,
                                                                                      //   item.status
                                                                                      // )}
                                                                                    >
                                                                                     {samplesarr[0]?.referencenumber}
                                                                                    </span>
                                                                                  );
                                                                                };
                                                                            }
                                                                            if (
                                                                              columns[i] ==
                                                                              "deliverystatus"
                                                                            ) {
                                                                              // obj.cellClass = 'pfinumberclass';
                                                                              obj.cellRenderer =
                                                                                (params) => {
                                                                                  var item =
                                                                                    params?.data;
                                                                                  var samplesarr =
                                                                                    JSON.parse(
                                                                                      item.samplesarr
                                                                                    );
                                                                                  return (
                                                                                    <span
                                                                                      className="clickable-age"
                                                                                      // onClick={() => clickproductname(
                                                                                      //     params?.node?.rowIndex,
                                                                                      //   item.id,
                                                                                      //   item.status
                                                                                      // )}
                                                                                    >
                                                                                     {samplesarr[0]?.deliverystatus}
                                                                                    </span>
                                                                                  );
                                                                                };
                                                                            }
                                                                            obj.rowDrag = columns[i] == 'sortid' ? true : false;
                                                                            if (!hidefilters.includes(columns[i])) {
                                                                                obj.filter = true;
                                                                                obj.filterParams = {
                                                                                  filterOptions: ["contains"],
                                                                                  maxNumConditions: 1,
                                                                                };
                                                                              }
                                                                          //   console.log('obj >>', obj)
                                                                            columnsarr.push(obj);
                                                                          }
                                                                        }
                                                                        //status
                                                              var statusobj = 
                                                              {
                                                                field: "status",
                                                                headerName: "Status",
                                                                wrapText: true,
                                                                autoHeight: true,
                                                                cellRenderer: (props) => {
                                                                  console.log('props >>', props?.node?.rowIndex);
                                                                  var item = props?.data;
                                                                  return (
                                                                    <div className="displayedittable" style={{justifyContent: 'flex-start'}}>
                                                                      <div
                                                                        className="statusdiv"
                                                                        onClick={() => {
                                                                          clickproductname(
                                                                            item
                                                                            //   props?.node?.rowIndex,
                                                                            // item.customerid,
                                                                            // item.id,
                                                                            // item.status
                                                                          );
                                                                          // console.log('item id >>>', props?.node?.rowIndex)
                                                                        }}
                                                                        style={
                                                                          item.status == "pending"
                                                                            ? { backgroundColor: "#ffde59" }
                                                                            : item.status == "accepted"
                                                                            ? {
                                                                                backgroundColor: "#4fb293",
                                                                                cursor: "unset",
                                                                              }
                                                                            : item.status ==
                                                                              "feedback pending"
                                                                            ? { backgroundColor: "#4fb293" }
                                                                            : item.status == "revised quote"
                                                                            ? { backgroundColor: "#ff3131" }
                                                                            : null
                                                                        }
                                                                      >
                                                                        {item.status}
                                                                      </div>
                                                                    </div>
                                                                  );
                                                                },
                                                                cellRendererParams: {
                                                                  onClick: clickhandle,
                                                                },
                                                              }
                                                              columnsarr.push(statusobj);
                                                                        //delete
                                                              var deleteobj = 
                                                              {
                                                                field: "setting",
                                                                headerName: "Settings",
                                                                cellRenderer: (props) => {
                                                                  
                                                                  return (
                                                                    
                                                                          <div className="displayedittable" style={{justifyContent: 'flex-start'}}>
                                                                            <div
                                                                              onClick={() => {
                                                                                  clickproductname1(props?.data?.id);
                                                                              }}
                                                                            >
                                                                              <img src={edit} className="editicon" />
                                                                            </div>
                                                                            {/* <div
                                                                              onClick={() => {
                                                                                  deleterfs(props?.data?.id)
                                                                              }}
                                                                            >
                                                                              <img src={deleteicon} className="editicon" />
                                                                            </div> */}
                                                                          </div>
                                                                  )
                                                                },
                                                                cellRendererParams: {
                                                                  onClick: clickhandle,
                                                                },
                                                              }
                                                              columnsarr.push(deleteobj);
                                                              setColDefs(columnsarr);
                                                              setRowData(prevdata);
                                                                  } else {
                                                                      setColDefs([]);
                                                                      setRowData([]);
                                                                  }
                            } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }

    const clickproductname = (item) => {
        if(item.status == 'accepted') {

       } else  if(item.status == 'revised sample') {
            navigate('/cms/admin/request-for-revised-sample/'+item.id);
        }
        else if(item.comment) {
          navigate('/cms/admin/request-for-revised-sample/'+item.id);
        }
        else {
            navigate('/cms/admin/reply-rfs/'+item.id);
        }
       
    }
    const clickproductname1 = (id) => {
        navigate('/cms/admin/edit-rfs/'+id);
    }

    const deleterfs = async(id) => {
      if(window.confirm("Are you sure you want to delete this sample")) {
      setshowloding(true);
      var data = {
          'rfsid' : id,
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'admindeleterfs',data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              setrequestsampledata(res.data.data);
              // dispatch({ type: "userdata", payload: res.data.data })
              //  navigate('/cms/customer/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
  } else {
      return
  }
  }

    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchrequestsampledata();
        } else {
            navigate('/cms/admin/login');
        }
    }, []);

    
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={'Admin'} />
        </div>
        <div className="main-layout">
        <Navbar name={'Admin'} />
            <div className="mainDiv">
            <p className="oneplaceHead">REQUEST FOR SAMPLE</p>
            <div
                                                          className={"ag-theme-quartz"}
                                                          style={{ width: "100%", marginTop: 15 }}
                                                        >
                                                            <AgGridReact
                                                                        // modules={AllModules}
                                                                        rowData={rowData}
                                                                        columnDefs={colDefs}
                                                                        defaultColDef={defaultColDef}
                                                                        // suppressRowClickSelection={true}
                                                                        // groupSelectsChildren={true}
                                                                        // rowSelection={"multiple"}
                                                                        // rowDragManaged={true}
                                                                        animateRows={true}
                                                                        domLayout="autoHeight"
                                                                        onRowDragEnd={(params) => {
                                                                          console.log('Row drag ended:', params);
                                                                        }}
                                                                        pagination={true}
                                                                        paginationPageSize={10}
                                                                        paginationPageSizeSelector={paginationPageSizeSelector}
                                                                        // rowHeight={80}
                                                                        // onGridReady={onGridReady}
                                                                      />
                                                        </div>
                                                        <br /><br />
            {/* {
                requestsampledata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Product Name</th>
                    <th>Application</th>
                    <th>Ref. No.</th>
                    <th>Delivery Status</th>
                    <th>RFS No.</th>
                    <th>Customer Name</th>
                    <th>Status</th>
                    <th>Settings</th>
                </thead>
                <tbody>
                    {
                        requestsampledata?.map((item, index) => {
                            // console.log('item ..', item)
                            var samplesarr = JSON.parse(item?.samplesarr);
                            console.log('samplesarr >>', samplesarr[0]);
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                <ol type="a">
                                        {
                                            samplesarr?.map((itm, ind) => {
                                                return <li style={{textAlign: 'left', paddingLeft: 5}}>{itm?.productname}</li>
                                            })
                                        }
                                    </ol>
                                </td>
                                <td>{samplesarr[0].application}</td>
                                <td>
                                  {samplesarr[0].referencenumber
                                    ? samplesarr[0].referencenumber
                                    : "-"}
                                </td>
                                <td>
                                  {samplesarr[0].deliverystatus
                                    ? samplesarr[0].deliverystatus
                                    : "-"}
                                </td>
                                <td>{item.rfsnumber}</td>
                                <td>{item.name ? item.name : 'Super admin'}</td>
                                <td>
                                    <div onClick={() => {clickproductname(item)}} className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59', cursor: 'pointer'} : item.status == 'accepted' ? {backgroundColor: '#4fb293', cursor: 'unset'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293', cursor: 'pointer'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131', cursor: 'pointer'} :  null}>
                                        {item.status}
                                    </div>
                                 </td>
                                <td>
                                  <div className="displayedittable">
                                  {
                                    item.status == 'accepted' ?
                                    <div onClick={() => {deleterfs(item.id)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div> :
                                    <div onClick={() => {clickproductname1(item.id)}}>
                                        <img src={edit} className="editicon" />
                                    </div>
                                  }
                                    
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                <p style={{textAlign: 'center'}}>No request yet.</p>
            </div>
            } */}
            
            </div>
        </div>
      </div>
    );
}

export default AdminRequestSample