import React, { useEffect, useState, useMemo, useRef } from "react";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../config/config";
import axios from "axios";
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import WebNavbar from "../../components/common/WebNavbar";
import WebSidebar from "../../components/common/WebSidebar";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const WebAdminApplicationTags = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [showloding, setshowloding] = useState(false);
  const [categoriesdata, setcategoriesdata] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [editshowmodal, seteditshowmodal] = useState(false);
  const [categoryname, setcategoryname] = useState(null);
  const [orderid, setorderid] = useState(null);
  const [selectedid, setselectedid] = useState(0);
  const [showsavebutton, setshowsavebutton] = useState(false);

   const [rowData, setRowData] = useState([]);
        const [colDefs, setColDefs] = useState([]);
        const paginationPageSizeSelector = useMemo(() => {
          return [10, 20, 50, 100];
        }, []);
    
         const [inputvaluechange, setinputvaluechange] = useState({});
          const [forceupdate, setforceupdate] = useState(false);
          const inputvaluechangeRef = useRef();
          inputvaluechangeRef.current = inputvaluechange;
    
          const categoriesdataRef = useRef();
            categoriesdataRef.current = categoriesdata;

            const handleupdateurlbtn = async(id, value, productname) => {
    
              // console.log('id >>', id);
              // console.log('value >>', value);
              // console.log('productname >>', productname);
              // console.log('rowData >>', categoriesdataRef.current);
          
              if(value && value > 0) {
                const updatedArray = [...categoriesdataRef.current];
              const targetIndex = updatedArray.findIndex((item) => item.id === id);
              if (targetIndex > -1) {
                const [movedItem] = updatedArray.splice(targetIndex, 1);
                updatedArray.splice(value - 1, 0, movedItem);
              }
              // return updatedArray;
              console.log('updatedArray >>', updatedArray);
          
              setshowloding(false);
              var data = {'data' : updatedArray};
              const headers = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userdata?.token,
                  },
                };
                let resp = await axios
                  .post(apiUrl + "orderapplicationtags", data, headers)
                  .then((res) => {
                    // console.log('res >>>', res.data)
                    if (res.data.status == "success") {
                      setshowsavebutton(false);
                      window.location.reload();
                      fetchdata();
                      setshowloding(false);
                    } else {
                      alert(res.data.message);
                      setshowloding(false);
                    }
                  })
                  .catch((err) => {
                    setshowloding(false);
                    alert(err.message);
                  });
                setshowloding(false);
              } else {
                alert('sort preference cannot be empty or less then 1');
              }
          
              
            }
            const handlechangeurl = (id, value) => {
              // console.log('id >>', id);
              // console.log('value >>', value);
          
          
              // console.log('value >>', value);
              var prevdata = inputvaluechangeRef.current;
              // prevdata[id] = value;
              
              if(prevdata[id]) {
                // console.log('found >>', prevdata[id]);
                prevdata[id] = value
              } else {
                // console.log('not found >>', prevdata[id]);
                prevdata[id] = value
              }
              console.log('prevdata >>', prevdata);
              setinputvaluechange(prevdata);
              setforceupdate(!forceupdate)
            }
          
            const clickhandle = () =>  {
              // console.log('Mission Launched')
            }

  const deletecategory = async (id) => {
    if (window.confirm("Are you sure you want to delete this tag")) {
      setshowloding(true);
      var data = {
        id: id,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "deleteapplicationtag", data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            fetchdata();
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
      setshowloding(false);
    } else {
      return;
    }
  };

  const submithandle = async () => {
    if (categoryname) {
      setshowloding(true);
      var data = {
        name: categoryname,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewpopularapplicationtag", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setcategoryname("");
            fetchdata();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

  const submithandle1 = async() => {
    if (categoryname) {
        setshowloding(true);
        var data = {
            id: selectedid,
            name: categoryname,
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "editapplicationtag", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              seteditshowmodal(false);
              setselectedid(0);
              setcategoryname("");
              fetchdata();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      } else {
        alert("Empty Field");
      }
  }

  const editcategoryname = async(id, name) => {
    setselectedid(id);
    setcategoryname(name);
    seteditshowmodal(true);
  }

  const fetchdata = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "getapplicationtagslist", headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
           setcategoriesdata(res.data.data);
                    var prevdata = res.data.data;
                              var columnsarr = [];
                              var hidecolumns = ['id', 'preferenceid', 'created_at', 'updated_at'];
                                var hidefilters = [];
                                var columns = Object.keys(prevdata[0]);
                    
                                for(var i = 0; i < columns?.length;i++) {
                                  if(!hidecolumns.includes(columns[i])) {
                                    var obj = {};
                                    obj.field = columns[i];
                                    obj.rowDrag = columns[i] == 'sortid' ? true : false;
                                    if (!hidefilters.includes(columns[i])) {
                                        obj.filter = true;
                                        obj.filterParams = {
                                          filterOptions: ["contains"],
                                          maxNumConditions: 1,
                                        };
                                      }
                                    console.log('obj >>', obj)
                                    columnsarr.push(obj);
                                  }
                                }
                                //sort obj
                                var sortobj = 
                                {
                                  field: "sort",
                                  headerName: "Sort",
                                  // filter : true,
                                  // filterParams : {
                                  //         filterOptions: ["contains"],
                                  //         maxNumConditions: 1,
                                  //       },
                                  cellRenderer: (props) => {
                                    
                                    return (
                                      
                                            // <div className="displayedittable">
                                            // <p>{props.data.sortid}</p>
                                            // </div>
                                            <div className="admintrackingdiv">
                                            <div className="admintrackingbox">
                                                <input type="number" min={1} defaultValue={Object.keys(inputvaluechangeRef.current)?.length > 0 ? inputvaluechangeRef.current[props?.data?.id] : props?.data?.preferenceid} value={null} onChange={(t) => {handlechangeurl(props?.data?.id, t.target.value)}} className="form-control admininput admintableinput" />
                                                <button className="btn btn-primary updateurlbtn" onClick={() => {handleupdateurlbtn(props?.data?.id, inputvaluechangeRef.current[props?.data?.id], props?.data?.name)}}>Sort</button>
                                            </div>
                                         </div>
                                    )
                                  },
                                  cellRendererParams: {
                                    onClick: clickhandle,
                                  },
                                  width: 300
                                }
                                columnsarr.push(sortobj);
                    
                                //delete
                                var deleteobj = 
                                {
                                  field: "setting",
                                  headerName: "Settings",
                                  cellRenderer: (props) => {
                                    
                                    return (
                                      
                                            <div className="displayedittable" style={{justifyContent: 'flex-start'}}>
                                              <div
                                                onClick={() => {
                                                  editcategoryname(props?.data?.id, props?.data?.name);
                                                }}
                                              >
                                                <img src={edit} className="editicon" />
                                              </div>
                                              <div
                                                onClick={() => {
                                                    deletecategory(props?.data?.id);
                                                }}
                                              >
                                                <img src={deleteicon} className="editicon" />
                                              </div>
                                            </div>
                                    )
                                  },
                                  cellRendererParams: {
                                    onClick: clickhandle,
                                  },
                                }
                                columnsarr.push(deleteobj);
                                setColDefs(columnsarr);
                                setRowData(prevdata);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };


  const [rows, setRows] = useState([
    { id: 1, name: 'Kiran' },
    { id: 2, name: 'Manohar' },
    { id: 3, name: 'Nandi' },
    // Add more rows as needed
  ]);

  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedIndex === null || draggedIndex === index) {
      return;
    }

    const updatedRows = [...categoriesdata];
    const draggedRow = updatedRows[draggedIndex];
    updatedRows.splice(draggedIndex, 1);
    updatedRows.splice(index, 0, draggedRow);

    console.log('updatedRows >>', updatedRows);

    setcategoriesdata(updatedRows);
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setshowsavebutton(true);
    setDraggedIndex(null);
  };

  const setprefernceapihit = async() => {
    setshowloding(false);
    var data = {'data' : categoriesdata};
    const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "orderapplicationtags", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowsavebutton(false);
            fetchdata();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
  }

  const defaultColDef = {
    flex: 1,
    resizable: true,
    sortable: true 
  };

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      fetchdata();
    } else {
      navigate("/WebAdminlogin");
    }
  }, []);
  return (
    <div className="HomeMain">
      <Overlay showloader={showloding} />
      <div className="sidebar-div">
        <WebSidebar name={"Admin"} />
      </div>
      <div className="main-layout">
        <WebNavbar name={"Admin"} />
        <div className="mainDiv">
          <p className="oneplaceHead">POPULAR APPLICATION TAGS</p>
          <div className="tablebuttondiv tablebuttondivpage">
            {
                showsavebutton ?
                <Link
              onClick={() => {
                setprefernceapihit();
              }}
              className="btndiv webadminmaindiv"
              style={{marginRight: 10}}
            >
              <p>{"Save"}</p>
            </Link> :
            null
            }
          
            <Link
              onClick={() => {
                setcategoryname("");
                setshowmodal(true);
              }}
              className="btndiv webadminmaindiv"
            >
              <p>{"Add Application Tag"}</p>
            </Link>
          </div>
          <div
                                          className={"ag-theme-quartz"}
                                          style={{ width: "100%", height: 500, marginTop: 15 }}
                                        >
                                          <AgGridReact
                                            // modules={AllModules}
                                            rowData={rowData}
                                            columnDefs={colDefs}
                                            defaultColDef={defaultColDef}
                                            // suppressRowClickSelection={true}
                                            // groupSelectsChildren={true}
                                            // rowSelection={"multiple"}
                                            // rowDragManaged={true}
                                            animateRows={true}
                                            domLayout="autoHeight"
                                            onRowDragEnd={(params) => {
                                              console.log('Row drag ended:', params);
                                            }}
                                            pagination={true}
                                            paginationPageSize={10}
                                            paginationPageSizeSelector={paginationPageSizeSelector}
                                            // onGridReady={onGridReady}
                                          />
                                        </div>
          {/* {categoriesdata?.length > 0 ? (
            <table class="table table-bordered invoicetable">
              <thead>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Preference Id</th>
                <th>Settings</th>
              </thead>
              <tbody>
                {categoriesdata?.map((item, index) => {
                  return (
                    <tr key={item.id}
                    onDragStart={() => handleDragStart(index)}
                    onDragOver={() => handleDragOver(index)}
                    onDragEnd={handleDragEnd}
                    draggable>
                      <td>{index + 1}</td>
                      <td>{'#'+item.name}</td>
                      <td>{item.preferenceid}</td>
                      <td>
                        <div className="displayedittable">
                          <div
                            onClick={() => {
                              editcategoryname(item.id, item.name);
                            }}
                          >
                            <img src={edit} className="editicon" />
                          </div>
                          <div
                            onClick={() => {
                                deletecategory(item.id);
                            }}
                          >
                            <img src={deleteicon} className="editicon" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="">
              <p style={{ textAlign: "center" }}>No data yet.</p>
            </div>
          )} */}

          {/*Add modal */}
          <Modal
            show={showmodal}
            onHide={() => setshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Hash Tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Tag</label>
                  <input
                    value={categoryname}
                    onChange={(t) => {
                      setcategoryname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Tag Without Hash"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle();
                  }}
                >
                  <p>Submit</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/*Edit modal */}
          <Modal
            show={editshowmodal}
            onHide={() => seteditshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Main Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Name</label>
                  <input
                    value={categoryname}
                    onChange={(t) => {
                      setcategoryname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Category name"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle1();
                  }}
                >
                  <p>Update</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>


          {/* Draggable */}
          {/* <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={row.id}
            onDragStart={() => handleDragStart(index)}
            onDragOver={() => handleDragOver(index)}
            onDragEnd={handleDragEnd}
            draggable
          >
            <td>{row.id}</td>
            <td>{row.name}</td>
          </tr>
        ))}
      </tbody>
    </table> */}
        </div>
      </div>
    </div>
  );
};

export default WebAdminApplicationTags;
