import React, { useEffect, useState, useMemo, useRef } from "react";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../config/config";
import axios from "axios";
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";
import WebNavbar from "../../components/common/WebNavbar";
import WebSidebar from "../../components/common/WebSidebar";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import external from '../../assets/images/external.png';

import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPenToSquare, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";


const WebAdminProductsList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [showloding, setshowloding] = useState(false);
  const [categoriesdata, setcategoriesdata] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [editshowmodal, seteditshowmodal] = useState(false);
  const [categoryname, setcategoryname] = useState(null);
  const [orderid, setorderid] = useState(null);
  const [selectedid, setselectedid] = useState(0);
  const [showsavebutton, setshowsavebutton] = useState(false);
  const [excelfile, setexcelfile] = useState(null);

  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const categoriesdataRef = useRef();
  categoriesdataRef.current = categoriesdata;

  const [inputvaluechange, setinputvaluechange] = useState({});
  const [forceupdate, setforceupdate] = useState(false);
  const inputvaluechangeRef = useRef();
  inputvaluechangeRef.current = inputvaluechange;

  const deletecategory = async (id) => {
    if (window.confirm("Are you sure you want to delete this product")) {
      setshowloding(true);
      var data = {
        id: id,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "deleteproduct", data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            fetchdata();
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
      setshowloding(false);
    } else {
      return;
    }
  };

  const submitexcelfile = async () => {
    if (excelfile) {
      setshowloding(true);
      const formData = new FormData();
      formData.append('excelfile', excelfile);

      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "importproductprocess", formData, headers)
        .then((res) => {
          console.log('res >>>', res.data)
          // if (res.data.status == "success") {
          //   setshowmodal(false);
          //   setexcelfile("");
          //   fetchdata();
          //   setshowloding(false);
          // } else {
          //   alert(res.data.message);
          //   setshowloding(false);
          // }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

  const submithandle1 = async() => {
    if (categoryname) {
        setshowloding(true);
        var data = {
            id: selectedid,
            name: categoryname,
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "editnewtag", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              seteditshowmodal(false);
              setselectedid(0);
              setcategoryname("");
              fetchdata();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      } else {
        alert("Empty Field");
      }
  }

  const editcategoryname = async(id, name) => {
    setselectedid(id);
    setcategoryname(name);
    seteditshowmodal(true);
  }

  const uploaddocsfunc = async(files) => {
    if(files[0]) {
      setexcelfile(files[0]);
    }
    
  }

  const clickhandle = () =>  {
    // console.log('Mission Launched')
  }

  const defaultColDef = {
    flex: 1,
    resizable: true,
    sortable: true 
  };
  console.log('categoriesdata >>', categoriesdata);

  const handleupdateurlbtn = async(id, value, productname) => {
    
    // console.log('id >>', id);
    // console.log('value >>', value);
    // console.log('productname >>', productname);
    // console.log('rowData >>', categoriesdataRef.current);

    if(value && value > 0) {
      const updatedArray = [...categoriesdataRef.current];
    const targetIndex = updatedArray.findIndex((item) => item.id === id);
    if (targetIndex > -1) {
      const [movedItem] = updatedArray.splice(targetIndex, 1);
      updatedArray.splice(value - 1, 0, movedItem);
    }
    // return updatedArray;
    console.log('updatedArray >>', updatedArray);

    setshowloding(false);
    var data = {'data' : updatedArray};
    const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "orderproducts", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowsavebutton(false);
            window.location.reload();
            fetchdata();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert('sort preference cannot be empty or less then 1');
    }

    
  }
  const handlechangeurl = (id, value) => {
    // console.log('id >>', id);
    // console.log('value >>', value);


    // console.log('value >>', value);
    var prevdata = inputvaluechangeRef.current;
    // prevdata[id] = value;
    
    if(prevdata[id]) {
      // console.log('found >>', prevdata[id]);
      prevdata[id] = value
    } else {
      // console.log('not found >>', prevdata[id]);
      prevdata[id] = value
    }
    console.log('prevdata >>', prevdata);
    setinputvaluechange(prevdata);
    setforceupdate(!forceupdate)
  }

  const fetchdata = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "getproductslist", headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          setcategoriesdata(res.data.data);
          var prevdata = res.data.data;
          if(prevdata?.length > 0) {
            var columnsarr = [];
            var hidecolumns = 
            [
              "id",
              "productid",
              "categoryid",
              "subcategoryid",
              "threetiercategoryid",
              "images",
              "tags",
              "status",
              "created_at",
              "updated_at",
              "catname",
              "catslug",
              "subcatname",
              "subcatslug",
              "threecatslug",
              "sortid"
          ]
            var hidefilters = [];
            // var columns = Object.keys(prevdata[0]);
            var columns = [
              "id",
              "productName",
              "application",
              "kevincode",
              "threeTiercateory",
              "sortid",
              "subcategoryid",
              "threetiercategoryid",
              "images",
              "tags",
              "status",
              "created_at",
              "updated_at",
              "catname",
              "catslug",
              "subcatname",
              "subcatslug",
              "threecatslug"
          ];
            console.log('columns  >>>', columns ); 


            for(var i = 0; i < columns?.length;i++) {
              if(!hidecolumns.includes(columns[i])) {
                var obj = {};
                obj.field = columns[i];
                obj.rowDrag = columns[i] == 'sortid' ? true : false;
                if (!hidefilters.includes(columns[i])) {
                    obj.filter = true;
                    obj.filterParams = {
                      filterOptions: ["contains"],
                      maxNumConditions: 1,
                    };
                  }
                console.log('obj >>', obj)
                columnsarr.push(obj);
              }
            }
            //sort obj
            var sortobj = 
            {
              field: "sort",
              headerName: "Sort",
              // filter : true,
              // filterParams : {
              //         filterOptions: ["contains"],
              //         maxNumConditions: 1,
              //       },
              cellRenderer: (props) => {
                
                return (
                  
                        // <div className="displayedittable">
                        // <p>{props.data.sortid}</p>
                        // </div>
                        <div className="admintrackingdiv">
                        <div className="admintrackingbox">
                            <input type="number" min={1} defaultValue={Object.keys(inputvaluechangeRef.current)?.length > 0 ? inputvaluechangeRef.current[props?.data?.id] : props?.data?.sortid} value={null} onChange={(t) => {handlechangeurl(props?.data?.id, t.target.value)}} className="form-control admininput admintableinput" />
                            <button className="btn btn-primary updateurlbtn" onClick={() => {handleupdateurlbtn(props?.data?.id, inputvaluechangeRef.current[props?.data?.id], props?.data?.productName)}}>Sort</button>
                        </div>
                     </div>
                )
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
              width: 300
            }
            columnsarr.push(sortobj);

            //delete
            var deleteobj = 
            {
              field: "setting",
              headerName: "Settings",
              cellRenderer: (props) => {
                
                return (
                  
                        <div className="displayedittable">
                        <div
                            onClick={() => {
                              gotoproducturl(props?.data);
                            }}
                          >
                            <img src={external} className="editicon" />
                          </div>
                          <div
                            onClick={() => {
                              navigate('/webadmin/edit-product/'+props?.data?.productid)
                            }}
                          >
                            <img src={edit} className="editicon" />
                          </div>
                          <div
                            onClick={() => {
                                deletecategory(props?.data?.productid);
                            }}
                          >
                            <img src={deleteicon} className="editicon" />
                          </div>
                        </div>
                )
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
            }
            columnsarr.push(deleteobj);
            setColDefs(columnsarr);
            setRowData(prevdata);
          } else {
            setColDefs([]);
            setRowData([]);
          }
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };


  const [rows, setRows] = useState([
    { id: 1, name: 'Kiran' },
    { id: 2, name: 'Manohar' },
    { id: 3, name: 'Nandi' },
    // Add more rows as needed
  ]);

  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedIndex === null || draggedIndex === index) {
      return;
    }

    const updatedRows = [...categoriesdata];
    const draggedRow = updatedRows[draggedIndex];
    updatedRows.splice(draggedIndex, 1);
    updatedRows.splice(index, 0, draggedRow);

    console.log('updatedRows >>', updatedRows);

    setcategoriesdata(updatedRows);
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setshowsavebutton(true);
    setDraggedIndex(null);
  };

  const setprefernceapihit = async() => {
    setshowloding(false);
    var data = {'data' : categoriesdata};
    const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "orderproducts", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowsavebutton(false);
            fetchdata();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
  }

  const gotoproducturl = (item) => {
    // console.log('item >>', item );
    var url =  '/products/'+item.catslug+'/'+item.subcatslug+'/'+item.threecatslug+'/'+item.productname+'/'+item.id;
    // console.log('url >>', url)
    window.open(url, '_blank');
  }

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      fetchdata();
    } else {
      navigate("/WebAdminlogin");
    }
  }, []);
  return (
    <div className="HomeMain">
      <Overlay showloader={showloding} />
      <div className="sidebar-div">
        <WebSidebar name={"Admin"} />
      </div>
      <div className="main-layout">
        <WebNavbar name={"Admin"} />
        <div className="mainDiv">
          <p className="oneplaceHead">PRODUCTS LIST</p>
          <div className="tablebuttondiv tablebuttondivpage">
            {
                showsavebutton ?
                <Link
              onClick={() => {
                setprefernceapihit();
              }}
              className="btndiv webadminmaindiv"
              style={{marginRight: 10}}
            >
              <p>{"Save"}</p>
            </Link> :
            null
            }
          
            <Link
            style={{marginRight: 15}}
            to={'/webadmin/add-product'}
              className="btndiv webadminmaindiv"
            >
              <p>{"Add Product"}</p>
            </Link>
            <Link
              onClick={() => {
                setshowmodal(true);
              }}
              className="btndiv webadminmaindiv"
            >
              <p>{"Import Products"}</p>
            </Link>
          </div>
            
          <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: 500, marginTop: 15 }}
        >
          <AgGridReact
            // modules={AllModules}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            // suppressRowClickSelection={true}
            // groupSelectsChildren={true}
            // rowSelection={"multiple"}
            // rowDragManaged={true}
            animateRows={true}
            domLayout="autoHeight"
            onRowDragEnd={(params) => {
              console.log('Row drag ended:', params);
            }}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={paginationPageSizeSelector}
            // onGridReady={onGridReady}
          />
        </div>
            

          {/* {categoriesdata?.length > 0 ? (
            <table class="table table-bordered invoicetable">
              <thead>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Application</th>
                <th>Kevin Code</th>
                <th>Three Tier Category</th>
                <th>Preference Id</th>
                <th>Settings</th>
              </thead>
              <tbody>
                {categoriesdata?.map((item, index) => {
                  return (
                    <tr key={item.id}
                    onDragStart={() => handleDragStart(index)}
                    onDragOver={() => handleDragOver(index)}
                    onDragEnd={handleDragEnd}
                    draggable>
                      <td>{index + 1}</td>
                      <td>{item.productName}</td>
                      <td>{item.name}</td>
                      <td>{item.kevincode}</td>
                      <td>{item.threeTiercateory}</td>
                      
                      <td>{item.sortid}</td>
                      <td>
                        <div className="displayedittable">
                        <div
                            onClick={() => {
                              gotoproducturl(item);
                            }}
                          >
                            <img src={external} className="editicon" />
                          </div>
                          <div
                            onClick={() => {
                              navigate('/webadmin/edit-product/'+item.productid)
                            }}
                          >
                            <img src={edit} className="editicon" />
                          </div>
                          <div
                            onClick={() => {
                                deletecategory(item.productid);
                            }}
                          >
                            <img src={deleteicon} className="editicon" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="">
              <p style={{ textAlign: "center" }}>No data yet.</p>
            </div>
          )} */}



          {/*Add modal */}
          <Modal
            show={showmodal}
            onHide={() => setshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Import Products</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Upload CSV</label>
                  <input
                    type="file"
                    style={{marginTop: 5}}
                    onChange={(t) => {uploaddocsfunc(t.target.files)}} accept=".csv"
                    className="form-control tabinput"
                    placeholder="Enter Tag Without Hash"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submitexcelfile();
                  }}
                >
                  <p>Submit</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/*Edit modal */}
          <Modal
            show={editshowmodal}
            onHide={() => seteditshowmodal(false)}
            size="md"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Main Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="">
                  <label>Name</label>
                  <input
                    value={categoryname}
                    onChange={(t) => {
                      setcategoryname(t.target.value);
                    }}
                    type="text"
                    className="form-control tabinput"
                    placeholder="Enter Category name"
                  />
                </div>
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle1();
                  }}
                >
                  <p>Update</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>


          {/* Draggable */}
          {/* <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={row.id}
            onDragStart={() => handleDragStart(index)}
            onDragOver={() => handleDragOver(index)}
            onDragEnd={handleDragEnd}
            draggable
          >
            <td>{row.id}</td>
            <td>{row.name}</td>
          </tr>
        ))}
      </tbody>
    </table> */}
        </div>
      </div>
    </div>
  );
};

export default WebAdminProductsList;
