import React, {useEffect, useState, useMemo} from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";

import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SuperdminProformaInvoice = () => {
    const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [requestpfidata, setrequestpfidata] = useState([]);
    const [showloding, setshowloding] = useState(false);
    const [rowData, setRowData] = useState([]);
          const [colDefs, setColDefs] = useState([]);
          const paginationPageSizeSelector = useMemo(() => {
            return [10, 20, 50, 100];
          }, []);
    
          const defaultColDef = {
            flex: 1,
            resizable: true,
            sortable: true 
          };

          const clickhandle = () =>  {
            // console.log('Mission Launched')
          }


          const fetchpfidata = async() => {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.get(apiUrl + 'superadminfetchpfi', headers).then((res) => {
                // console.log('res >>>', res.data)
                  if(res.data.status == 'success') {
                    setrequestpfidata(res.data.data);
                    var prevdata = res.data.data;
                    if(prevdata?.length > 0) {
                        var columnsarr = [];
                        var hidecolumns = [
                            "id",
                            "customerid",
                            "pfidoc",
                            "pssdata",
                            "pssdate",
                            "pssawbill",
                            "pssisskipped",
                            "ispssapproved",
                            "psscustomercomment",
                            "psscustomerdocs",
                            "dsdadmindocs",
                            "dsdcomment",
                            "dsdcustomerdoc",
                            "isdsdapproved",
                            "fsddocs",
                            "fsddate",
                            "fsdcourier",
                            "fsdawbnumber",
                            "fsdawbdoc",
                            "fsdamendment",
                            "adminstatus",
                            "customerstatus",
                            "note",
                            "created_at",
                            "updated_at",
                            "is_rectification",
                            "step",
                            "pfidate"
                        ];
                        var hidefilters = [];
                        // var columns = Object.keys(prevdata[0]);
                        var columns = [
                            "id",
                            "name",
                            "pfinumber",
                            "pfidate",
                            "ponumber",
                            "customerid",
                            "pfidoc",
                            "pssdata",
                            "pssdate",
                            "pssawbill",
                            "pssisskipped",
                            "ispssapproved",
                            "psscustomercomment",
                            "psscustomerdocs",
                            "dsdadmindocs",
                            "dsdcomment",
                            "dsdcustomerdoc",
                            "isdsdapproved",
                            "fsddocs",
                            "fsddate",
                            "fsdcourier",
                            "fsdawbnumber",
                            "fsdawbdoc",
                            "fsdamendment",
                            "adminstatus",
                            "customerstatus",
                            "note",
                            "created_at",
                            "updated_at",
                            "is_rectification",
                            "step",
                            "fsdinvoicenumber"
                        ]
                        console.log('columns >>', columns);
                        for(var i = 0; i < columns?.length;i++) {
                            if(!hidecolumns.includes(columns[i])) {
                              var obj = {};
                              obj.field = columns[i];
                              obj.headerName = columns[i] == 'name' ?
                              'Customer Name' :
                              columns[i] == 'pfinumber' ? 'PFI Number' :
                              columns[i] == 'pfidate' ? 'PFI Date' :
                              columns[i] == 'fsdinvoicenumber' ? 'Invoice Number' :
                              columns[i] == 'ponumber' ? 'PO Number'
                              : null;
                              obj.wrapText = true;
                              obj.autoHeight = true;
                              if(columns[i] == 'name') {
                                obj.cellClass = 'invoicenamecell';
                              }
                            if(columns[i] == 'pfinumber') {
                                obj.cellClass = 'pfinumberclass';
                                obj.cellRenderer = (params) => (
                                    <span 
                                        className="clickable-age" 
                                        onClick={() => clickproductnamerfi(params?.data?.id)}
                                    >
                                        {params.value}
                                    </span>
                                );
                            }
                              obj.rowDrag = columns[i] == 'sortid' ? true : false;
                              if (!hidefilters.includes(columns[i])) {
                                  obj.filter = true;
                                  obj.filterParams = {
                                    filterOptions: ["contains"],
                                    maxNumConditions: 1,
                                  };
                                }
                            //   console.log('obj >>', obj)
                              columnsarr.push(obj);
                            }
                          }
                          //status
                var statusobj = 
                {
                  field: "status",
                  headerName: "Status",
                  wrapText: true,
                  autoHeight: true,
                  cellRenderer: (props) => {
                    
                    return (
                      
                            <div className="displayedittable">
                              {
                                    props?.data?.is_rectification == true ?
                                    <div onClick={() => {clickproductnamerfi(props?.data?.id)}} className="statusdiv" style={{backgroundColor: '#ffde59', borderColor: 'red', borderWidth: 3, borderStyle: 'solid'}}>
                                        Request rectification
                                    </div>
                                    
                                   : props?.data?.adminstatus == -1 ?
                                    <div onClick={() => {clickproductnamerfi(props?.data?.id)}} className="statusdiv" style={{backgroundColor: '#4fb293'}}>
                                        Complete
                                    </div>
                                     :
                                     <div onClick={() => {clickproductnamerfi(props?.data?.id)}} className="statusdiv" style={{backgroundColor: '#ffde59'}}>
                                        {
                                        props?.data?.adminstatus == 1 ? 'Proforma Invoice' : props?.data?.adminstatus == 2 ? 'Pre-shipment Sample' : props?.data?.adminstatus == 3 ? 'Draft Shipping Documents' : 'Final Shipping Documents'}
                                    </div>
                                    
                                    }
                            </div>
                    )
                  },
                  cellRendererParams: {
                    onClick: clickhandle,
                  },
                }
                columnsarr.push(statusobj);
                          //delete
                var deleteobj = 
                {
                  field: "setting",
                  headerName: "Settings",
                  cellRenderer: (props) => {
                    
                    return (
                      
                            <div className="displayedittable">
                              <div
                                onClick={() => {
                                    clickproductnamerfi(props?.data?.id);
                                }}
                              >
                                <img src={edit} className="editicon" />
                              </div>
                              <div
                                onClick={() => {
                                    deleterfi(props?.data?.id)
                                }}
                              >
                                <img src={deleteicon} className="editicon" />
                              </div>
                            </div>
                    )
                  },
                  cellRendererParams: {
                    onClick: clickhandle,
                  },
                }
                columnsarr.push(deleteobj);
                setColDefs(columnsarr);
                setRowData(prevdata);
                    } else {
                        setColDefs([]);
                        setRowData([]);
                    }
                  } else {
                    alert(res.data.message);
                  }
              } ).catch((err) => {
                  alert(err.message)
              })
        }
    const deleterfi = async(id) => {
        if(window.confirm("Are you sure you want to delete this quote")) {
        setshowloding(true);
        var data = {
            'invid' : id,
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'superadmindeleterfi',data, headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestpfidata(res.data.data);
                // setrequestsampledata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                 navigate('/cms/superadmin/proformaInvoice');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    } else {
        return
    }
    }
    const clickproductnamerfi = (id) => {
        navigate('/cms/superadmin/new-pfi-tracking/'+id);
    }

    const clicknewpfi = () => {
        navigate('/cms/superadmin/new-pfi-tracking/'+'-2');
    }

    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchpfidata();
        } else {
            navigate('/cms/superadmin/login');
        }
    }, []);

    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={'superadmin'} />
        </div>
        <div className="main-layout">
            <Navbar name={'superadmin'} />
            <div className="mainDiv">
                {/* <InvoiceTable title={'Proforma Invoices'} data={invoicedata} showbtn={true} /> */}
                <p className="oneplaceHead">PROFORMA INVOICES</p>
                <div className="tablebuttondiv tablebuttondivpage" onClick={() => {
                    clicknewpfi()
                }}>
                <Link className="btndiv" style={{backgroundColor: colors.themeBlue}}>
                    <p>{'New PFI'}</p>
                </Link>
            </div>
            <div
                      className={"ag-theme-quartz"}
                      style={{ width: "100%", marginTop: 15 }}
                    >
                        <AgGridReact
                                    // modules={AllModules}
                                    rowData={rowData}
                                    columnDefs={colDefs}
                                    defaultColDef={defaultColDef}
                                    // suppressRowClickSelection={true}
                                    // groupSelectsChildren={true}
                                    // rowSelection={"multiple"}
                                    // rowDragManaged={true}
                                    animateRows={true}
                                    domLayout="autoHeight"
                                    onRowDragEnd={(params) => {
                                      console.log('Row drag ended:', params);
                                    }}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={paginationPageSizeSelector}
                                    // rowHeight={80}
                                    // onGridReady={onGridReady}
                                  />
                    </div>
            
                {/* {
                requestpfidata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Customer Name</th>
                    <th>PFI No.</th>
                    <th>PFI Date</th>
                    <th>PO No.</th>
                    <th>Status</th>
                    <th>Settings</th>
                </thead>
                <tbody>
                    {
                        requestpfidata?.map((item, index) => {
                            console.log('item ..', item)
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>
                                    <div onClick={() => {clickproductnamerfi(item.id)}}>
                                        <Link>{item.pfinumber}</Link>
                                    </div>
                                </td>
                                <td>{item.pfidate}</td>
                                <td>{item.ponumber}</td>
                                <td>{
                                item.is_rectification == true ?
                                <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59', borderColor: 'red', borderWidth: 3, borderStyle: 'solid'}}>
                                    Request rectification
                                </div>
                                
                               : item.adminstatus == -1 ?
                                <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#4fb293'}}>
                                    Complete
                                </div>
                                 :
                                 <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59'}}>
                                    {
                                    item.adminstatus == 1 ? 'Proforma Invoice' : item.adminstatus == 2 ? 'Pre-shipment Sample' : item.adminstatus == 3 ? 'Draft Shipping Documents' : 'Final Shipping Documents'}
                                </div>
                                
                                }</td>
                                <td>
                                  <div className="displayedittable">
                                    <div onClick={() => {deleterfi(item.id)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div>
                                    <div onClick={() => {clickproductnamerfi(item.id)}}>
                                        <img src={edit} className="editicon" />
                                    </div>
                                    
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                <p style={{textAlign: 'center'}}>No invoices yet.</p>
            </div>
            } */}
            </div>
        </div>
      </div>
    );
}

export default SuperdminProformaInvoice