import React, {useEffect, useState, useMemo} from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";

import { Link } from "react-router-dom";
import { colors } from "../../config/colors";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { edit, deleteicon } from "../../config/images";

import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const AdminHome = () => {
    const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const invoicedata = [
        {
            'Sr. No.' : '1',
            'PFI No.' : 'KDCPL/001/23-24',
            'PFI Date': '01/04/2023',
            'PO No.': 'XXX XXX XXX',
            'Status': 'Complete',
            'Edit' : ''
        }, 
        {
            'Sr. No.' : '2',
            'PFI No.' : 'KDCPL/002/23-24',
            'PFI Date': '01/04/2023',
            'PO No.': 'XXX XXX XXX',
            'Status': 'In Process',
            'Edit' : ''
        },
        {
            'Sr. No.' : '3',
            'PFI No.' : 'KDCPL/003/23-24',
            'PFI Date': '01/04/2023',
            'PO No.': 'XXX XXX XXX',
            'Status': 'Complete',
            'Edit' : ''
        }
    ];
    const [showloding, setshowloding] = useState(false);
    const [requestsampledata, setrequestsampledata] = useState([]);
    const [requestquotedata, setrequestquotedata] = useState([]);
    const [requestpfidata, setrequestpfidata] = useState([]);

    const [rowData, setRowData] = useState([]);
          const [colDefs, setColDefs] = useState([]);
          const paginationPageSizeSelector = useMemo(() => {
            return [10, 20, 50, 100];
          }, []);
    
          const [rowData1, setRowData1] = useState([]);
          const [colDefs1, setColDefs1] = useState([]);
          const paginationPageSizeSelector1 = useMemo(() => {
            return [10, 20, 50, 100];
          }, []);
    
          const [rowData2, setRowData2] = useState([]);
          const [colDefs2, setColDefs2] = useState([]);
          const paginationPageSizeSelector2 = useMemo(() => {
            return [10, 20, 50, 100];
          }, []);

          const defaultColDef = {
            flex: 1,
            resizable: true,
            sortable: true 
          };

          const clickhandle = () =>  {
            // console.log('Mission Launched')
          }

    const fetchrequestrfqdata = async() => {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'adminfetchrfq', headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                              setrequestquotedata(res.data.data);
                              // dispatch({ type: "userdata", payload: res.data.data })
                              //  navigate('/customer/requestsample');
                              var prevdata = res.data.data;
                                                                  if(prevdata?.length > 0) {
                                                                      var columnsarr = [];
                                                                      var hidecolumns = [
                                                                          "id",
                                                                          "customerid",
                                                                          
                                                                          "quantity",
                                                                          
                                                                          
                                                                          "currency",
                                                                          "estimation",
                                                                          "shipment",
                                                                          "dischargeport",
                                                                          "arrivalport",
                                                                          "inco",
                                                                          "price",
                                                                          "units",
                                                                          "validity",
                                                                         
                                                                          
                                                                          "status",
                                                                          "comment",
                                                                          "note",
                                                                          "created_at",
                                                                          "updated_at",
                                                                          "name"
                                                                      ];
                                                                      var hidefilters = [];
                                                                      // var getcolumns = Object.keys(prevdata[0]);
                                                                      // console.log('getcolumns >>', getcolumns);
                                                                      var columns = [
                                                                          "id",
                                                                          "customerid",
                                                                          "productname",
                                                                          "quantity",
                                                                          "kevincode",
                                                                          "reference",
                                                                          "application",
                                                                          "currency",
                                                                          "estimation",
                                                                          "shipment",
                                                                          "dischargeport",
                                                                          "arrivalport",
                                                                          "inco",
                                                                          "price",
                                                                          "units",
                                                                          "validity",
                                                                          "validity1",
                                                                          "rfqnumber",
                                                                          "status",
                                                                          "comment",
                                                                          "note",
                                                                          "created_at",
                                                                          "updated_at",
                                                                          "name"
                                                                      ]
                                                                      console.log('columns >>', columns);
                                                                      for(var i = 0; i < columns?.length;i++) {
                                                                          if(!hidecolumns.includes(columns[i])) {
                                                                            var obj = {};
                                                                            obj.field = columns[i];
                                                                            obj.headerName = columns[i] == 'productname' ?
                                                                            'Product Name' :
                                                                            columns[i] == 'kevincode' ? 'Kevin Code' :
                                                                            columns[i] == 'reference' ? 'Ref. No.' :
                                                                            columns[i] == 'validity1' ? 'Validity' :
                                                                            columns[i] == 'rfqnumber' ? 'RFQ No.'
                                                                            : null;
                                                                          //   obj.wrapText = true;
                                                                          //   obj.autoHeight = true;
                                                                          if(columns[i] == 'rfqnumber') {
                                                                              obj.cellClass = 'pfinumberclass';
                                                                              obj.cellRenderer = (params) => {
                                                                                  var item = params?.data;
                                                                                  return (
                                                                                      <span 
                                                                                      className="clickable-age" 
                                                                                      onClick={() => clickproductnamerfq(
                                                                                        item.id,
                                                                                        item.status
                                                                                      )}
                                                                                  >
                                                                                      {params.value}
                                                                                  </span>
                                                                                  )
                                                                                  
                                                                              };
                                                                          }
                                                                            obj.rowDrag = columns[i] == 'sortid' ? true : false;
                                                                            if (!hidefilters.includes(columns[i])) {
                                                                                obj.filter = true;
                                                                                obj.filterParams = {
                                                                                  filterOptions: ["contains"],
                                                                                  maxNumConditions: 1,
                                                                                };
                                                                              }
                                                                          //   console.log('obj >>', obj)
                                                                            columnsarr.push(obj);
                                                                          }
                                                                        }
                                                                        //status
                                                              var statusobj = 
                                                              {
                                                                field: "status",
                                                                headerName: "Status",
                                                                wrapText: true,
                                                                autoHeight: true,
                                                                cellRenderer: (props) => {
                                                                  console.log('props >>', props?.node?.rowIndex);
                                                                  var item = props?.data;
                                                                  return (
                                                                    <div className="displayedittable" style={{justifyContent: 'flex-start'}}>
                                                                      <div
                                                                        className="statusdiv"
                                                                        onClick={() => {
                                                                          clickproductnamerfq(
                                                                            item.id,
                                                                            item.status
                                                                          );
                                                                          // console.log('item id >>>', props?.node?.rowIndex)
                                                                        }}
                                                                        style={
                                                                          item.status == "pending"
                                                                            ? { backgroundColor: "#ffde59" }
                                                                            : item.status == "accepted"
                                                                            ? {
                                                                                backgroundColor: "#4fb293",
                                                                                cursor: "unset",
                                                                              }
                                                                            : item.status ==
                                                                              "feedback pending"
                                                                            ? { backgroundColor: "#4fb293" }
                                                                            : item.status == "revised quote"
                                                                            ? { backgroundColor: "#ff3131" }
                                                                            : null
                                                                        }
                                                                      >
                                                                        {item.status}
                                                                      </div>
                                                                    </div>
                                                                  );
                                                                },
                                                                cellRendererParams: {
                                                                  onClick: clickhandle,
                                                                },
                                                              }
                                                              columnsarr.push(statusobj);
                                                                        //delete
                                                              var deleteobj = 
                                                              {
                                                                field: "setting",
                                                                headerName: "Settings",
                                                                cellRenderer: (props) => {
                                                                  
                                                                  return (
                                                                    
                                                                          <div className="displayedittable" style={{justifyContent: 'flex-start'}}>
                                                                            <div
                                                                              onClick={() => {
                                                                                clickproductnamerfq1(props?.data?.id);
                                                                              }}
                                                                            >
                                                                              <img src={edit} className="editicon" />
                                                                            </div>
                                                                            {/* <div
                                                                              onClick={() => {
                                                                                  deleterfq(props?.data?.id)
                                                                              }}
                                                                            >
                                                                              <img src={deleteicon} className="editicon" />
                                                                            </div> */}
                                                                          </div>
                                                                  )
                                                                },
                                                                cellRendererParams: {
                                                                  onClick: clickhandle,
                                                                },
                                                              }
                                                              columnsarr.push(deleteobj);
                                                              setColDefs1(columnsarr);
                                                              setRowData1(prevdata);
                                                                  } else {
                                                                      setColDefs1([]);
                                                                      setRowData1([]);
                                                                  }
                            } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
    }

    const fetchpfidata = async() => {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'adminfetchpfi', headers).then((res) => {
            // console.log('res >>>', res.data)
               if(res.data.status == 'success') {
                              setrequestpfidata(res.data.data);
                              var prevdata = res.data.data;
                              if(prevdata?.length > 0) {
                                  var columnsarr = [];
                                  var hidecolumns = [
                                      "id",
                                      "customerid",
                                      "pfidoc",
                                      "pssdata",
                                      "pssdate",
                                      "pssawbill",
                                      "pssisskipped",
                                      "ispssapproved",
                                      "psscustomercomment",
                                      "psscustomerdocs",
                                      "dsdadmindocs",
                                      "dsdcomment",
                                      "dsdcustomerdoc",
                                      "isdsdapproved",
                                      "fsddocs",
                                      "fsddate",
                                      "fsdcourier",
                                      "fsdawbnumber",
                                      "fsdawbdoc",
                                      "fsdamendment",
                                      "adminstatus",
                                      "customerstatus",
                                      "note",
                                      "pfidate",
                                      "created_at",
                                      "updated_at",
                                      "is_rectification",
                                      "step"
                                  ];
                                  var hidefilters = [];
                                  // var columns = Object.keys(prevdata[0]);
                                  var columns = [
                                      "id",
                                      "name",
                                      "pfinumber",
                                      "pfidate",
                                      "ponumber",
                                      "customerid",
                                      "pfidoc",
                                      "pssdata",
                                      "pssdate",
                                      "pssawbill",
                                      "pssisskipped",
                                      "ispssapproved",
                                      "psscustomercomment",
                                      "psscustomerdocs",
                                      "dsdadmindocs",
                                      "dsdcomment",
                                      "dsdcustomerdoc",
                                      "isdsdapproved",
                                      "fsddocs",
                                      "fsddate",
                                      "fsdcourier",
                                      "fsdawbnumber",
                                      "fsdawbdoc",
                                      "fsdamendment",
                                      "adminstatus",
                                      "customerstatus",
                                      "note",
                                      "created_at",
                                      "updated_at",
                                      "is_rectification",
                                      "step",
                                      "fsdinvoicenumber"
                                  ]
                                  console.log('columns >>', columns);
                                  for(var i = 0; i < columns?.length;i++) {
                                      if(!hidecolumns.includes(columns[i])) {
                                        var obj = {};
                                        obj.field = columns[i];
                                        obj.headerName = columns[i] == 'name' ?
                              'Customer Name' :
                              columns[i] == 'pfinumber' ? 'PFI Number' :
                              columns[i] == 'fsdinvoicenumber' ? 'Invoice Number' :
                              columns[i] == 'ponumber' ? 'PO Number'
                              : null;
                          obj.wrapText = true;
                          obj.autoHeight = true;
                        if(columns[i] == 'name') {
                          obj.cellClass = 'invoicenamecell';
                        }
                                        obj.wrapText = true;
                                        obj.autoHeight = true;
                                      if(columns[i] == 'pfinumber') {
                                          obj.cellClass = 'pfinumberclass';
                                          obj.cellRenderer = (params) => (
                                              <span 
                                                  className="clickable-age" 
                                                  onClick={() => clickproductnamerfi(params?.data?.id)}
                                              >
                                                  {params.value}
                                              </span>
                                          );
                                      }
                                        obj.rowDrag = columns[i] == 'sortid' ? true : false;
                                        if (!hidefilters.includes(columns[i])) {
                                            obj.filter = true;
                                            obj.filterParams = {
                                              filterOptions: ["contains"],
                                              maxNumConditions: 1,
                                            };
                                          }
                                      //   console.log('obj >>', obj)
                                        columnsarr.push(obj);
                                      }
                                    }
                                    //status
                          var statusobj = 
                          {
                            field: "status",
                            headerName: "Status",
                            wrapText: true,
                            autoHeight: true,
                            cellRenderer: (props) => {
                              var item = props?.data
                              return (
                                
                                      <div className="displayedittable">
                                        {
                                item.is_rectification == true ?
                                <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59', borderColor: 'red', borderWidth: 3, borderStyle: 'solid'}}>
                                    Request rectification
                                </div>
                                
                               : item.adminstatus == -1 ?
                                <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#4fb293'}}>
                                    Complete
                                </div>
                                 :
                                 <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59'}}>
                                    {
                                    item.adminstatus == 1 ? 'Proforma Invoice' : item.adminstatus == 2 ? 'Pre-shipment Sample' : item.adminstatus == 3 ? 'Draft Shipping Documents' : 'Final Shipping Documents'}
                                </div>
                                
                                }
                                      </div>
                              )
                            },
                            cellRendererParams: {
                              onClick: clickhandle,
                            },
                          }
                          columnsarr.push(statusobj);
                                    //delete
                          var deleteobj = 
                          {
                            field: "setting",
                            headerName: "Settings",
                            cellRenderer: (props) => {
                              var item = props?.data
                              return (
                                
                                <div className="displayedittable">
                                {
                                  item.adminstatus == -1 ?
                                  <div onClick={() => {clickproductnamerfi(item.id)}}>
                                      <img src={edit} className="editicon" />
                                  </div>
                                   :
                                  <div onClick={() => {deleterfi(item.id)}}>
                                    <img src={deleteicon} className="editicon" />
                                  </div>
                                }
                                  
                                </div>
                                      
                              )
                            },
                            cellRendererParams: {
                              onClick: clickhandle,
                            },
                          }
                          columnsarr.push(deleteobj);
                          setColDefs(columnsarr);
                          setRowData(prevdata);
                              } else {
                                  setColDefs([]);
                                  setRowData([]);
                              }
                            } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
    }

    const clickproductnamerfq = (id, status) => {
        if(status == 'accepted') {
            navigate('/cms/admin/request-for-quote/'+id);
       } else  if(status == 'revised quote') {
            navigate('/cms/admin/request-for-revised-quote/'+id);
        } else {
            navigate('/cms/admin/request-for-quote/'+id);
        }
       
    }
    const clickproductnamerfq1 = (id) => {
        navigate('/cms/admin/edit-rfq/'+id);
    }
    const deleterfq = async(id) => {
        if(window.confirm("Are you sure you want to delete this quote")) {
        setshowloding(true);
        var data = {
            'rfqid' : id,
        }
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.post(apiUrl + 'admindeleterfq',data, headers).then((res) => {
            // console.log('res >>>', res.data)
              if(res.data.status == 'success') {
                setrequestsampledata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/cms/customer/requestsample');
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    } else {
        return
    }
    }

    const fetchrequestsampledata = async() => {
        setshowloding(true);
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + userdata?.token
            }
        }
        let resp = await axios.get(apiUrl + 'adminfetchrfs', headers).then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
                setrequestsampledata(res.data.data);
                // dispatch({ type: "userdata", payload: res.data.data })
                //  navigate('/cms/customer/requestsample');
                var prevdata = res.data.data;
                if (prevdata?.length > 0) {
                  var columnsarr = [];
                  var hidecolumns = [
                    "id",
                    "customerid",
                    "quantity",
                    "dispatchdate",
                    "status",
                    "document",
                    "adminsamples",
                    "comment",
                    "revisedocument",
                    "feedbackdocument",
                    "note",
                    "samplesarr",
                    "created_at",
                    "updated_at",
                  ];
                  var hidefilters = [];
                  var getcolumns = Object.keys(prevdata[0]);
                  console.log("getcolumns >>", getcolumns);
                  var columns = [
                    "id",
                    "customerid",
                    "productname",
                    "quantity",
                    "application",
                    "referencenumber",
                    "deliverystatus",
                    "dispatchdate",
                    "rfsnumber",
                    "status",
                    "document",
                    "adminsamples",
                    "comment",
                    "revisedocument",
                    "feedbackdocument",
                    "note",
                    "samplesarr",
                    "created_at",
                    "updated_at",
                    "name",
                  ];
                  console.log("columns >>", columns);
                  for (var i = 0; i < columns?.length; i++) {
                    if (!hidecolumns.includes(columns[i])) {
                      var obj = {};
                      obj.field = columns[i];
                      obj.headerName =
                        columns[i] == "productname"
                          ? "Product Name"
                          : columns[i] == "deliverystatus"
                          ? "Delivery Status"
                          : columns[i] == "referencenumber"
                          ? "Ref. No."
                          : columns[i] == "rfsnumber"
                          ? "RFS No."
                          : columns[i] == "name"
                          ? "Customer Name"
                          : null;
                      //   obj.wrapText = true;
                      //   obj.autoHeight = true;
                      if (columns[i] == "productname") {
                        // obj.cellClass = 'pfinumberclass';
                        obj.cellRenderer = (params) => {
                          var item = params?.data;
                          var samplesarr = JSON.parse(item.samplesarr);
                          return (
                            <span
                              className="clickable-age"
                              // onClick={() => clickproductname(
                              //     params?.node?.rowIndex,
                              //   item.id,
                              //   item.status
                              // )}
                            >
                              <ol type="a">
                                {samplesarr?.map((itm, ind) => {
                                  return (
                                    <li
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      {itm?.productname}
                                    </li>
                                  );
                                })}
                              </ol>
                            </span>
                          );
                        };
                      }
                      if (columns[i] == "application") {
                        // obj.cellClass = 'pfinumberclass';
                        obj.cellRenderer = (params) => {
                          var item = params?.data;
                          var samplesarr = JSON.parse(item.samplesarr);
                          return (
                            <span
                              className="clickable-age"
                              // onClick={() => clickproductname(
                              //     params?.node?.rowIndex,
                              //   item.id,
                              //   item.status
                              // )}
                            >
                              {samplesarr[0]?.application}
                            </span>
                          );
                        };
                      }
                      if (columns[i] == "referencenumber") {
                        // obj.cellClass = 'pfinumberclass';
                        obj.cellRenderer = (params) => {
                          var item = params?.data;
                          var samplesarr = JSON.parse(item.samplesarr);
                          return (
                            <span
                              className="clickable-age"
                              // onClick={() => clickproductname(
                              //     params?.node?.rowIndex,
                              //   item.id,
                              //   item.status
                              // )}
                            >
                              {samplesarr[0]?.referencenumber}
                            </span>
                          );
                        };
                      }
                      if (columns[i] == "deliverystatus") {
                        // obj.cellClass = 'pfinumberclass';
                        obj.cellRenderer = (params) => {
                          var item = params?.data;
                          var samplesarr = JSON.parse(item.samplesarr);
                          return (
                            <span
                              className="clickable-age"
                              // onClick={() => clickproductname(
                              //     params?.node?.rowIndex,
                              //   item.id,
                              //   item.status
                              // )}
                            >
                              {samplesarr[0]?.deliverystatus}
                            </span>
                          );
                        };
                      }
                      obj.rowDrag = columns[i] == "sortid" ? true : false;
                      if (!hidefilters.includes(columns[i])) {
                        obj.filter = true;
                        obj.filterParams = {
                          filterOptions: ["contains"],
                          maxNumConditions: 1,
                        };
                      }
                      //   console.log('obj >>', obj)
                      columnsarr.push(obj);
                    }
                  }
                  //status
                  var statusobj = {
                    field: "status",
                    headerName: "Status",
                    wrapText: true,
                    autoHeight: true,
                    cellRenderer: (props) => {
                      console.log("props >>", props?.node?.rowIndex);
                      var item = props?.data;
                      return (
                        <div
                          className="displayedittable"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div
                            className="statusdiv"
                            onClick={() => {
                              clickproductname(
                                item
                              );
                              // console.log('item id >>>', props?.node?.rowIndex)
                            }}
                            style={
                              item.status == "pending"
                                ? { backgroundColor: "#ffde59" }
                                : item.status == "accepted"
                                ? {
                                    backgroundColor: "#4fb293",
                                    cursor: "unset",
                                  }
                                : item.status == "feedback pending"
                                ? { backgroundColor: "#4fb293" }
                                : item.status == "revised quote"
                                ? { backgroundColor: "#ff3131" }
                                : null
                            }
                          >
                            {item.status}
                          </div>
                        </div>
                      );
                    },
                    cellRendererParams: {
                      onClick: clickhandle,
                    },
                  };
                  columnsarr.push(statusobj);
                  //delete
                  var deleteobj = {
                    field: "setting",
                    headerName: "Settings",
                    cellRenderer: (props) => {
                      return (
                        <div
                          className="displayedittable"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div
                            onClick={() => {
                              clickproductname1(props?.data?.id);
                            }}
                          >
                            <img src={edit} className="editicon" />
                          </div>
                          {/* <div
                            onClick={() => {
                              deleterfs(props?.data?.id);
                            }}
                          >
                            <img src={deleteicon} className="editicon" />
                          </div> */}
                        </div>
                      );
                    },
                    cellRendererParams: {
                      onClick: clickhandle,
                    },
                  };
                  columnsarr.push(deleteobj);
                  setColDefs2(columnsarr);
                  setRowData2(prevdata);
                } else {
                  setColDefs2([]);
                  setRowData2([]);
                }
              } else {
                alert(res.data.message);
              }
          } ).catch((err) => {
              alert(err.message)
          })
        setshowloding(false)
    }

    const clickproductname = (item) => {
        if(item.status == 'accepted') {

        } else  if(item.status == 'revised sample') {
             navigate('/cms/admin/request-for-revised-sample/'+item.id);
         }
         else if(item.comment) {
           navigate('/cms/admin/request-for-revised-sample/'+item.id);
         }
         else {
             navigate('/cms/admin/reply-rfs/'+item.id);
         }
       
    }
    const clickproductname1 = (id) => {
        navigate('/cms/admin/edit-rfs/'+id);
    }

    const deleterfs = async(id) => {
      if(window.confirm("Are you sure you want to delete this sample")) {
      setshowloding(true);
      var data = {
          'rfsid' : id,
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'admindeleterfs',data, headers).then((res) => {
          // console.log('res >>>', res.data)
            if(res.data.status == 'success') {
              setrequestsampledata(res.data.data);
              // dispatch({ type: "userdata", payload: res.data.data })
              //  navigate('/cms/customer/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
  } else {
      return
  }
  }
  const deleterfi = async(id) => {
    if(window.confirm("Are you sure you want to delete this invoice")) {
    setshowloding(true);
    var data = {
        'invid' : id,
    }
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
    }
    let resp = await axios.post(apiUrl + 'admindeleterfi',data, headers).then((res) => {
        // console.log('res >>>', res.data)
          if(res.data.status == 'success') {
            setrequestpfidata(res.data.data);
            // dispatch({ type: "userdata", payload: res.data.data })
             navigate('/cms/admin/home');
          } else {
            alert(res.data.message);
          }
      } ).catch((err) => {
          alert(err.message)
      })
    setshowloding(false)
} else {
    return
}
}
const clickproductnamerfi = (id) => {
    navigate('/cms/admin/new-pfi-tracking/'+id);
}

  const fetchhomedata = async() => {
    setshowloding(true)
    await fetchpfidata();
    await fetchrequestrfqdata();
    await fetchrequestsampledata();
    setshowloding(false)
  }
  const clicknewpfi = () => {
    navigate('/cms/admin/new-pfi-tracking/'+'-2');
}


    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
            fetchhomedata();
        } else {
            navigate('/cms/admin/login');
        }
    }, []);
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={'Admin'} />
        </div>
        <div className="main-layout">
            <Navbar name={'Admin'} />
            <div className="mainDiv">
                <p className="oneplaceHead">ONE PLACE FOR EVERYTHING KEVIN</p>
                {/* <InvoiceTable title={'Proforma Invoices'} data={invoicedata} showbtn={true} showedit={true} /> */}
                <br /><br />
                <p className="proformaHead">Proforma Invoices</p>
                <div className="tablebuttondiv tablebuttondivpage" onClick={() => {
                    clicknewpfi()
                }}>
                <Link className="btndiv" style={{backgroundColor: colors.themeBlue}}>
                    <p>{'New PFI'}</p>
                </Link>
            </div>
            <div
                                  className={"ag-theme-quartz"}
                                  style={{ width: "100%", marginTop: 15 }}
                                >
                                  <AgGridReact
                                    // modules={AllModules}
                                    rowData={rowData}
                                    columnDefs={colDefs}
                                    defaultColDef={defaultColDef}
                                    // suppressRowClickSelection={true}
                                    // groupSelectsChildren={true}
                                    // rowSelection={"multiple"}
                                    // rowDragManaged={true}
                                    animateRows={true}
                                    domLayout="autoHeight"
                                    onRowDragEnd={(params) => {
                                      console.log('Row drag ended:', params);
                                    }}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={paginationPageSizeSelector}
                                    // rowHeight={80}
                                    // onGridReady={onGridReady}
                                  />
                                </div>
            {/* {
                requestpfidata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Customer Name</th>
                    <th>PFI No.</th>
                    <th>PFI Date</th>
                    <th>PO No.</th>
                    <th>Status</th>
                    <th>Settings</th>
                </thead>
                <tbody>
                    {
                        requestpfidata?.map((item, index) => {
                            // console.log('item ..', item)
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>
                                    <div onClick={() => {clickproductnamerfi(item.id)}}>
                                        <Link>{item.pfinumber}</Link>
                                    </div>
                                </td>
                                <td>{item.pfidate}</td>
                                <td>{item.ponumber}</td>
                                <td>{
                                item.is_rectification == true ?
                                <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59', borderColor: 'red', borderWidth: 3, borderStyle: 'solid'}}>
                                    Request rectification
                                </div>
                                
                               : item.adminstatus == -1 ?
                                <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#4fb293'}}>
                                    Complete
                                </div>
                                 :
                                 <div onClick={() => {clickproductnamerfi(item.id)}} className="statusdiv" style={{backgroundColor: '#ffde59'}}>
                                    {
                                    item.adminstatus == 1 ? 'Proforma Invoice' : item.adminstatus == 2 ? 'Pre-shipment Sample' : item.adminstatus == 3 ? 'Draft Shipping Documents' : 'Final Shipping Documents'}
                                </div>
                                
                                }</td>
                                <td>
                                  <div className="displayedittable">
                                  {
                                    item.adminstatus == -1 ?
                                    <div onClick={() => {clickproductnamerfi(item.id)}}>
                                        <img src={edit} className="editicon" />
                                    </div>
                                     :
                                    <div onClick={() => {deleterfi(item.id)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div>
                                  }
                                    
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                <p style={{textAlign: 'center'}}>No invoices yet.</p>
            </div>
            } */}
            <br /><br /><br />
                <p className="proformaHead">Request for Quotes</p>
                <div
                                  className={"ag-theme-quartz"}
                                  style={{ width: "100%", marginTop: 15 }}
                                >
                                  <AgGridReact
                                    // modules={AllModules}
                                    rowData={rowData1}
                                    columnDefs={colDefs1}
                                    defaultColDef={defaultColDef}
                                    // suppressRowClickSelection={true}
                                    // groupSelectsChildren={true}
                                    // rowSelection={"multiple"}
                                    // rowDragManaged={true}
                                    animateRows={true}
                                    domLayout="autoHeight"
                                    onRowDragEnd={(params) => {
                                      console.log('Row drag ended:', params);
                                    }}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={paginationPageSizeSelector1}
                                    // rowHeight={80}
                                    // onGridReady={onGridReady}
                                  />
                                </div>
            {/* {
                requestquotedata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Product Name</th>
                    <th>Kevin Code</th>
                    <th>Ref. No.</th>
                    <th>Application</th>
                    <th>Validity</th>
                    <th>RFQ No.</th>
                    <th>Status</th>
                    <th>Settings</th>
                </thead>
                <tbody>
                    {
                        requestquotedata?.map((item, index) => {
                            // console.log('item ..', item)
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.productname}</td>
                                <td>{item.kevincode}</td>
                                <td>{item.reference}</td>
                                <td>{item.application}</td>
                                <td>{item.validity1}</td>
                                <td>
                                    <div className="displayedittable" onClick={() => {clickproductnamerfq(item.id, item.status)}}>
                                            <Link >
                                                {item.rfqnumber}
                                            </Link>
                                        </div>
                                </td>
                                <td>
                                    <div className="statusdiv" onClick={() => {clickproductnamerfq(item.id, item.status)}} style={item.status == 'pending' ? {backgroundColor: '#ffde59'} : item.status == 'accepted' ? {backgroundColor: '#4fb293', cursor: 'pointer'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293'} : item.status == 'revised quote' ? {backgroundColor: '#ff3131'} :  null}>
                                        {item.status}
                                    </div>
                                </td>
                                <td>
                                  <div className="displayedittable">
                                  {
                                    item.status == 'accepted' ?
                                    <div onClick={() => {deleterfq(item.id)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div> :
                                    <div onClick={() => {clickproductnamerfq1(item.id)}}>
                                        <img src={edit} className="editicon" />
                                    </div>
                                  }
                                    
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
            </table> : 
            <div className="">
                <p style={{textAlign: 'center'}}>No quotes yet.</p>
            </div>
            } */}
            <br /><br /><br />
                <p className="proformaHead">Request for Samples</p>    
                <div
                                  className={"ag-theme-quartz"}
                                  style={{ width: "100%", marginTop: 15 }}
                                >
                                  <AgGridReact
                                    // modules={AllModules}
                                    rowData={rowData2}
                                    columnDefs={colDefs2}
                                    defaultColDef={defaultColDef}
                                    // suppressRowClickSelection={true}
                                    // groupSelectsChildren={true}
                                    // rowSelection={"multiple"}
                                    // rowDragManaged={true}
                                    animateRows={true}
                                    domLayout="autoHeight"
                                    onRowDragEnd={(params) => {
                                      console.log('Row drag ended:', params);
                                    }}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={paginationPageSizeSelector2}
                                    // rowHeight={80}
                                    // onGridReady={onGridReady}
                                  />
                                </div>
                                <br />
            {/* {
                requestsampledata?.length > 0 ?
                <table class="table table-bordered invoicetable">
                <thead>
                    <th>Sr no.</th>
                    <th>Product Name</th>
                    <th>Application</th>
                    <th>Ref. No.</th>
                    <th>Delivery Status</th>
                    <th>RFS No.</th>
                    <th>Customer Name</th>
                    <th>Status</th>
                    <th>Settings</th>
                </thead>
                <tbody>
                    {
                        requestsampledata?.map((item, index) => {
                            // console.log('item ..', item)
                            var samplesarr = JSON.parse(item?.samplesarr);
                            console.log('samplesarr >>', samplesarr[0]);
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                <ol type="a">
                                        {
                                            samplesarr?.map((itm, ind) => {
                                                return <li style={{textAlign: 'left', paddingLeft: 5}}>{itm?.productname}</li>
                                            })
                                        }
                                    </ol>
                                </td>
                                <td>{samplesarr[0].application}</td>
                                <td>
                                  {samplesarr[0].referencenumber
                                    ? samplesarr[0].referencenumber
                                    : "-"}
                                </td>
                                <td>
                                  {samplesarr[0].deliverystatus
                                    ? samplesarr[0].deliverystatus
                                    : "-"}
                                </td>
                                <td>{item.rfsnumber}</td>
                                <td>{item.name}</td>
                                <td>
                                    <div onClick={() => {clickproductname(item)}} className="statusdiv" style={item.status == 'pending' ? {backgroundColor: '#ffde59', cursor: 'pointer'} : item.status == 'accepted' ? {backgroundColor: '#4fb293', cursor: 'unset'} : item.status == 'feedback pending' ? {backgroundColor: '#4fb293', cursor: 'pointer'} : item.status == 'revised sample' ? {backgroundColor: '#ff3131', cursor: 'pointer'} :  null}>
                                        {item.status}
                                    </div>
                                 </td>
                                <td>
                                  <div className="displayedittable">
                                  {
                                    item.status == 'accepted' ?
                                    <div onClick={() => {deleterfs(item.id)}}>
                                      <img src={deleteicon} className="editicon" />
                                    </div> :
                                    <div onClick={() => {clickproductname1(item.id)}}>
                                        <img src={edit} className="editicon" />
                                    </div>
                                  }
                                    
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    }
                </tbody>
                </table> : 
                <div className="">
                    <p style={{textAlign: 'center'}}>No request yet.</p>
                </div>
            } */}
            
            </div>
        </div>
      </div>
    );
}

export default AdminHome