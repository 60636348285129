import React, { useState, useEffect } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import InvoiceTable from "../../components/tables/InvoiceTable";
import { pdf } from "../../config/images";
import { colors } from "../../config/colors";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from 'react-router-dom';
import { apiUrl } from "../../config/config";
import axios from 'axios';
import Overlay from "../../components/common/Overlay";
import { Link } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';


const CustomerOrderTracking = () => {
    const dispatch = useDispatch(); 
    const userdata = useSelector((store) => store.userReducer.userdata);
    let navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams();
    const [isapproved, setisapproved] = useState(false);
    const [showcomment, setshowcomment] = useState(false);
    const [showcomment2, setshowcomment2] = useState(false);
    const [showcomment3, setshowcomment3] = useState(false);
    const [isrejected, setisrejected] = useState(false);
    const [showloding, setshowloding] = useState(false);
    const [orderstep, setorderstep] = useState(1);
    const [orderstep1, setorderstep1] = useState(1);
    const [invoicedata, setinvoicedata] = useState({});
    const [psscomment, setpsscomment] = useState(null);
    const [pssfeedbackdoc, setpssfeedbackdoc] = useState(null);
    const [showpsswaiting, setshowpsswaiting] = useState(false);
    const [showdsswaiting, setshowdsswaiting] = useState(false);
    const [dsdfeedbackdoc, setdsdfeedbackdoc] = useState(null);
    const [dsdcomment, setdsdcomment] = useState(null);
    const [showfsdwaiting, setshowfsdwaiting] = useState(false);
    const [rectificationstep, setrectificationstep] = useState(0);
    const Samplesdata = [
        {
            'Sr. No.' : '1',
            'Product' : 'Pigment Blue 15:3',
            'Product Code': 'KeviPound 7015B3',
            'Lot. No.': 'KDCPL/0001',
            'Date': '10/6/2023'
        },
        {
          'Sr. No.' : '2',
          'Product' : 'Pigment Blue 15:3',
          'Product Code': 'KeviPound 7015B3',
          'Lot. No.': 'KDCPL/0002',
          'Date': '10/6/2023'
      },
      {
        'Sr. No.' : '3',
        'Product' : 'Pigment Blue 15:3',
        'Product Code': 'KeviPound 7015B3',
        'Lot. No.': 'KDCPL/0003',
        'Date': '10/6/2023'
    }
    ]
    const submitrfs1 = () => {
        navigate('/cms/customer/proformaInvoice');  
    }
    const submitrfs2 = () => {
        navigate('/cms/customer/requestquote');  
    }
    const fetchsingleinvoicedata = async(id) => {
      setshowloding(true);
      var data = {
          'invid' : id,
      }
      const headers = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' + userdata?.token
          }
      }
      let resp = await axios.post(apiUrl + 'customerfetchinvoicesingle',data, headers).then((res) => {
          console.log('customerfetchinvoicesingle res >>>', typeof res?.data?.data?.customerstatus)
            if(res.data.status == 'success') {
              
              setinvoicedata(res.data.data);
              if(res.data.data.customerstatus == 2) {
                setorderstep(res.data.data.customerstatus);
                if(res.data.data.is_rectification == true) {
                  setorderstep1(res.data.data.step);
                } else {
                  setorderstep1(res.data.data.customerstatus);
                }
                if(res.data.data.psscustomercomment) {
                  setshowpsswaiting(true);
                  setshowcomment2(false);
                } else {
                  setshowpsswaiting(false);
                }
                
              } else if(res.data.data.customerstatus == 3) { 
                setorderstep(res.data.data.customerstatus);
                if(res.data.data.is_rectification == true) {
                  setorderstep1(res.data.data.step);
                } else {
                  setorderstep1(res.data.data.customerstatus);
                }
                if(res.data.data.dsdadmindocs) {
                  if(res.data.data.dsdcomment) {
                    setshowdsswaiting(true);
                  } else {
                    setshowdsswaiting(false);
                  }
                } else {
                  setshowdsswaiting(true);
                }
                
              } else if(res.data.data.customerstatus == 4 || res.data.data.customerstatus == '-1') {
                setorderstep(4);
                if(res.data.data.is_rectification == true) {
                  setorderstep1(res.data.data.step);
                } else {
                  setorderstep1(4);
                }
                if(res.data.data.fsddocs) {

                } else {
                  setshowfsdwaiting(true);
                }
              }
              else {
                setorderstep(res.data.data.customerstatus);
                if(res.data.data.is_rectification == true) {
                  setorderstep1(res.data.data.step);
                } else {
                  setorderstep1(res.data.data.customerstatus);
                }
              }
              // setrequestsampledata(res.data.data);
              // dispatch({ type: "userdata", payload: res.data.data })
              //  navigate('/cms/customer/requestsample');
            } else {
              alert(res.data.message);
            }
        } ).catch((err) => {
            alert(err.message)
        })
      setshowloding(false)
    }
    const getBase64 = file => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          // console.log("Called", reader);
          baseURL = reader.result;
          // console.log(baseURL);
          resolve(baseURL);
        };
        // console.log(fileInfo);
      });
    };
    const uploaddocsfunc = async(files) => {
      if(files[0]) {
        setpssfeedbackdoc(null);
      var obj = {};
      await getBase64(files[0]).then(result => {
          obj['name'] = files[0].name;
          obj['image'] = result;
      });
      setpssfeedbackdoc(obj);
      }
      
  }
  const uploaddocsfuncdsd = async(files) => {
    if(files[0]) {
      setdsdfeedbackdoc(null);
    var obj = {};
    await getBase64(files[0]).then(result => {
        obj['name'] = files[0].name;
        obj['image'] = result;
    });
    setdsdfeedbackdoc(obj);
    }
    
}
  const submitpssrejection = async() => {
    if(psscomment) {
      setshowloding(true);
            var data = JSON.stringify(
              {
                'invid': id,
                'psscustomercomment': psscomment,
                'psscustomerdocs': pssfeedbackdoc,
              });
              // console.log('data .>', requestarrRef.current)
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'customerpssrejection', data, headers).then((res) => {
                if(res.data.status == 'success') {
                  setshowpsswaiting(true);
                  setshowcomment2(false);
                  // setorderstep(3);
                  //  navigate('/cms/customer/requestsample');
                  // setissubmitted(true);
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                alert(err.message)
            })
            setshowloding(false);
  } else {
    alert('Please enter comment.')
}
    
  }
  const pssapprovesubmit = async() => {
    setshowloding(true);
            var data = JSON.stringify(
              {
                'invid': id,
              });
              // console.log('data .>', requestarrRef.current)
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'customerpssapprove', data, headers).then((res) => {
                if(res.data.status == 'success') {
                  setorderstep(3);
                  setorderstep1(3);
                  setshowdsswaiting(true);
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                alert(err.message)
            })
            setshowloding(false);
  }
  const submitdsdrejection = async() => {
    if(dsdcomment ) {
      setshowloding(true);
            var data = JSON.stringify(
              {
                'invid': id,
                'dsdcomment': dsdcomment,
                'dsdcustomerdoc': dsdfeedbackdoc,
              });
              // console.log('data .>', requestarrRef.current)
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'customerdsdrejection', data, headers).then((res) => {
                if(res.data.status == 'success') {
                  setshowdsswaiting(true);
                  setshowcomment2(false);
                  // setorderstep(3);
                  //  navigate('/cms/customer/requestsample');
                  // setissubmitted(true);
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                alert(err.message)
            })
            setshowloding(false);
  } else {
    alert('Please add comment')
}
    
  }
  const submitdsdapprove = async() => {
    setshowloding(true);
            var data = JSON.stringify(
              {
                'invid': id,
              });
              // console.log('data .>', requestarrRef.current)
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + userdata?.token
                }
            }
            let resp = await axios.post(apiUrl + 'customerdsdapprove', data, headers).then((res) => {
                if(res.data.status == 'success') {
                  setorderstep(4);
                  setorderstep1(4);
                  setshowfsdwaiting(true);
                } else {
                  alert(res.data.message);
                }
            } ).catch((err) => {
                alert(err.message)
            })
            setshowloding(false);
  }
  console.log('userdata >>', userdata);

    useEffect(() => {
        if(userdata && Object.keys(userdata).length > 0) {
          fetchsingleinvoicedata(id);
        } else {
          navigate('/cms/customer/login');
        }
      
  }, []);
    return (
      <div className="HomeMain">
        <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar />
        </div>
        <div className="main-layout">
          <Navbar />
          {Object.keys(invoicedata).length > 0 ? (
            <div className="mainDiv">
              <p className="oneplaceHead">ORDER TRACKING</p>
              <div style={{ width: "100%", marginTop: -50 }}>
                <button
                  onClick={() => {
                    //   submitrfs();
                    // setshowcomment(true)
                    // setshowcomment3(false)
                    navigate("/cms/customer/request-rectification", {state: {invoiceid: invoicedata?.id, step: orderstep1}});
                  }}
                  className="submitrfs-btn submitrfs-btn2 submitrfs-red request-rectification-btn"
                  style={{
                    backgroundColor: colors.themeRed,
                    borderColor: colors.themeRed,
                    marginLeft: 20,
                  }}
                >
                  REQUEST RECTIFICATION
                </button>
              </div>

              <div className="bardiv">
                <div className="dotstring">
                  <div className="dots dots1"></div>
                  <div
                    className="dots"
                    style={
                      orderstep > 1
                        ? {
                            backgroundColor: colors.themeBlue,
                            borderColor: invoicedata?.is_rectification && orderstep1 == '1' ? 'red' : "white",
                          }
                        : null
                    }
                  ></div>
                  <div
                    className="dots"
                    style={
                      orderstep > 2
                        ? {
                            backgroundColor: colors.themeBlue,
                            borderColor: invoicedata?.is_rectification && orderstep1 == '2' ? 'red' : "white",
                          }
                        : orderstep == 2
                        ? {
                            backgroundColor: colors.themeyellow,
                            borderColor: colors.themeyellow,
                          }
                        : null
                    }
                  ></div>
                  <div
                    className="dots"
                    style={
                      orderstep > 3
                        ? {
                            backgroundColor: colors.themeBlue,
                            borderColor: invoicedata?.is_rectification && orderstep1 == '3' ? 'red' : "white",
                          }
                        : orderstep == 3
                        ? {
                            backgroundColor: colors.themeyellow,
                            borderColor: colors.themeyellow,
                          }
                        : null
                    }
                  ></div>
                  <div
                    className="dots"
                    style={
                      orderstep == 4
                        ? {
                            backgroundColor: colors.themeBlue,
                            borderColor: invoicedata?.is_rectification && orderstep1 == '4' ? 'red' : "white",
                          }
                        : null
                    }
                  ></div>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar progressbarbagcolor"
                    role="progressbar"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={
                      orderstep == 2
                        ? { width: "50%" }
                        : orderstep == 3
                        ? { width: "75%" }
                        : orderstep == 4
                        ? { width: "100%" }
                        : { width: "25%" }
                    }
                  ></div>
                </div>
                <div className="headstring">
                  <div
                    onClick={() => {setorderstep1(1)}}
                    className="headdiv"
                  >
                    <p className="headtext headtext1">
                      PROFORMA
                      <br />
                      INVOICE
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      // setorderstep(2);
                      if(orderstep > 1) {
                        setorderstep1(2);
                      } else {
  
                      }
                      
                    }}
                    className="headdiv"
                  >
                    <p className="headtext headtext2">
                      PRE SHIPMENT
                      <br />
                      SAMPLE
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      // setorderstep(3);
                      
                      if(orderstep > 2) {
                        setorderstep1(3);
                      } else {
                        
                      }
                    }}
                    className="headdiv"
                  >
                    <p className="headtext headtext3">
                      DRAFT SHIPPING
                      <br />
                      DOCUMENTS
                    </p>
                  </div>
                  <div
                    // onClick={() => orderstep > 3 ? setorderstep(4) : null}
                    onClick={() => {
                      // setorderstep(4);
                      if(orderstep > 3) {
                        setorderstep1(4);
                      } else {
                        
                      }
                    }}
                    className="headdiv"
                  >
                    <p className="headtext headtext4">
                      FINAL SHIPPING
                      <br />
                      DOCUMENTS
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="mt-3">
             <InvoiceTable title={''} data={Samplesdata} showbtn={false} />
            </div> */}

              {orderstep1 == 1 ? (
                <div className="orderscreen">
                  <p className="orderscreen-p">
                    Proforma Invoice Date : {invoicedata?.pfidate}
                  </p>
                  <p className="orderscreen-p">
                    Proforma Invoice No. : {invoicedata?.pfinumber}
                  </p>
                  {/* <div className="pdfrequestdiv proformainvoivediv">
                    <img src={pdf} style={{width: 32, height: 32}} />
                    <p style={{color: '#000', fontSize: 20, fontWeight: 'bold', marginLeft: 10, marginTop: 3}}>To view/download COA Click here</p>
                </div> */}
                  {/* {console.log('pfidoc .>', JSON.parse(invoicedata?.pfidoc).link)} */}
                  {
                    invoicedata?.pfidoc ?
                    <div className="pdfrequestdiv">
                    <img src={pdf} style={{ width: 32, height: 32 }} />
                    <Link
                      target="_blank"
                      to={JSON.parse(invoicedata?.pfidoc)?.link}
                      style={{ textDecoration: "none" }}
                    >
                      <p
                        style={{
                          color: "#000",
                          fontSize: 20,
                          fontWeight: "bold",
                          marginLeft: 10,
                          marginTop: 3,
                        }}
                      >
                        {JSON.parse(invoicedata?.pfidoc)?.name}
                      </p>
                    </Link>
                  </div> : null
                  }
                  
                </div>
              ) : orderstep1 == 2 ? (
                showpsswaiting ? (
                  <div className="orderscreen">
                    <p className="oneplaceHead">
                      WAITING FOR REVISED PRE SHIPMENT SAMPLE
                    </p>
                  </div>
                ) : (
                  <div className="orderscreen">
                    {/* <InvoiceTable title={''} data={Samplesdata} showbtn={false} /> */}
                    {invoicedata?.pssdata ? (
                      <>
                        <table className="table table-bordered invoicetable">
                          <thead>
                            <th>Sr no.</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Product Code</th>
                            <th>Lot No.</th>
                          </thead>
                          <tbody id="appendbody">
                            {JSON.parse(invoicedata?.pssdata)?.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.productname}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.productcode}</td>
                                    <td>{item.lotnumber}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                        <p className="orderscreen-p">
                          Pre Shipment Sample Date : {invoicedata?.pssdate}
                        </p>
                        {invoicedata?.pssawbill ? (
                          <div className="pdfrequestdiv">
                            <img src={pdf} style={{ width: 32, height: 32 }} />
                            <Link
                              target="_blank"
                              to={JSON.parse(invoicedata?.pssawbill).link}
                              style={{ textDecoration: "none" }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  marginLeft: 10,
                                  marginTop: 3,
                                }}
                              >
                                {JSON.parse(invoicedata?.pssawbill).name}
                              </p>
                            </Link>
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {showcomment2 ? (
                      <div className="commentbox">
                        <textarea
                          rows={4}
                          value={psscomment}
                          onChange={(t) => {
                            setpsscomment(t.target.value);
                          }}
                          className="form-control"
                          placeholder="comment your feedback here"
                        ></textarea>
                        <div
                          className="btnrequestsamplediv"
                          style={{
                            marginTop: 20,
                            justifyContent: "space-between",
                          }}
                        >
                          <label
                            onClick={() => {
                              // setisapproved(true)
                            }}
                            className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                            style={{
                              backgroundColor: colors.themeRed,
                              borderColor: colors.themeRed,
                            }}
                            for="upload-pdf"
                          >
                            Upload feedback
                          </label>
                          <input
                            type="file"
                            onChange={(t) => {
                              uploaddocsfunc(t.target.files);
                            }}
                            accept="application/pdf"
                            id="upload-pdf"
                          />
                          <button
                            onClick={() => {
                              //   submitrfs();
                              // setshowcomment(true)
                              submitpssrejection();
                              // setshowcomment2(false)
                              // setshowpsswaiting(true)
                            }}
                            className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                            style={{
                              backgroundColor: colors.themeRed,
                              borderColor: colors.themeRed,
                              marginLeft: 20,
                            }}
                          >
                            PSS REJECTED
                          </button>
                        </div>
                      </div>
                    ) : 
                    invoicedata?.ispssapproved !== '1' ?
                    invoicedata?.pssisskipped == true ? 
                    <p className="oneplaceHead" style={{textAlign : 'left'}}>PSS IS SKIPPED</p> :
                      <div className="btnrequestsamplediv ">
                        <button
                          onClick={() => {
                            // setorderstep(3)
                            // setisapproved(true)
                            pssapprovesubmit();
                          }}
                          className="submitrfs-btn submitrfs-btn2"
                          style={{
                            backgroundColor: colors.themeBlue,
                            borderColor: colors.themeBlue,
                          }}
                        >
                          PSS APPROVED
                        </button>
                        <button
                          onClick={() => {
                            //   submitrfs();
                            setshowcomment2(true);
                          }}
                          className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                          style={{
                            backgroundColor: colors.themeRed,
                            borderColor: colors.themeRed,
                            marginLeft: 20,
                          }}
                        >
                          PSS REJECTED
                        </button>
                      </div>
                      : null
                    }
                  </div>
                )
              ) : orderstep1 == 3 ? (
                showdsswaiting ? (
                  <div className="orderscreen">
                    <p className="oneplaceHead">
                      WAITING FOR DRAFT SHIPPING DOCUMENTS
                    </p>
                  </div>
                ) : (
                  <div className="orderscreen">
                    {invoicedata?.dsdadmindocs
                      ? JSON.parse(invoicedata?.dsdadmindocs)?.map(
                          (item, index) => {
                            return (
                              <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={item.link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                </Link>
                              </div>
                            );
                          }
                        )
                      : null}

                    {showcomment3 ? (
                      <div className="commentbox">
                        <textarea
                          rows={4}
                          value={dsdcomment}
                          onChange={(t) => {
                            setdsdcomment(t.target.value);
                          }}
                          className="form-control"
                          placeholder="comment your feedback here"
                        ></textarea>
                        <div
                          className="btnrequestsamplediv"
                          style={{
                            marginTop: 20,
                            justifyContent: "space-between",
                          }}
                        >
                          <label
                            onClick={() => {
                              // setisapproved(true)
                            }}
                            className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                            style={{
                              backgroundColor: colors.themeRed,
                              borderColor: colors.themeRed,
                            }}
                            for="upload-pdf1"
                          >
                            Upload feedback
                          </label>
                          <input
                            type="file"
                            onChange={(t) => {
                              uploaddocsfuncdsd(t.target.files);
                            }}
                            accept="application/pdf"
                            id="upload-pdf1"
                          />
                          <button
                            onClick={() => {
                              submitdsdrejection();
                            }}
                            className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                            style={{
                              backgroundColor: colors.themeRed,
                              borderColor: colors.themeRed,
                              marginLeft: 20,
                            }}
                          >
                            REQUEST RECTIFICATION
                          </button>
                        </div>
                      </div>
                    ) : 
                    invoicedata?.isdsdapproved !== '1' ?
                      <div className="btnrequestsamplediv ">
                        <button
                          onClick={() => {
                            // setorderstep(4);
                            submitdsdapprove();
                            // setisapproved(true)
                          }}
                          className="submitrfs-btn submitrfs-btn2"
                          style={{
                            backgroundColor: colors.themeBlue,
                            borderColor: colors.themeBlue,
                          }}
                        >
                          DRAFTS APPROVED
                        </button>
                        <button
                          onClick={() => {
                            //   submitrfs();
                            setshowcomment3(true);
                          }}
                          className="submitrfs-btn submitrfs-btn2 submitrfs-red"
                          style={{
                            backgroundColor: colors.themeRed,
                            borderColor: colors.themeRed,
                            marginLeft: 20,
                          }}
                        >
                          DRAFTS REJECTED
                        </button>
                      </div> : null
                    }
                  </div>
                )
              ) : orderstep1 == 4 ? (
                showfsdwaiting ? (
                  <div className="orderscreen">
                    <p className="oneplaceHead">
                      WAITING FOR FINAL SHIPPING DOCUMENTS
                    </p>
                  </div>
                ) : (
                  <div className="orderscreen">
                    {invoicedata?.fsddocs
                      ? JSON.parse(invoicedata?.fsddocs)?.map(
                          (item, index) => {
                            return (
                              <div className="pdfrequestdiv">
                                <img
                                  src={pdf}
                                  style={{ width: 32, height: 32 }}
                                />
                                <Link
                                  target="_blank"
                                  to={item?.link}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p
                                    style={{
                                      color: "#000",
                                      fontSize: 20,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                      marginTop: 3,
                                    }}
                                  >
                                    {item?.name}
                                  </p>
                                </Link>
                              </div>
                            );
                          }
                        )
                      : null}
                    <p className="orderscreen-p mt-5">
                      AWB Date : {invoicedata?.fsddate}
                    </p>
                    <p className="orderscreen-p">
                      AWB No. : {invoicedata?.fsdawbnumber}
                    </p>
                    <p className="orderscreen-p">
                      Courier : {invoicedata?.fsdcourier}
                    </p>
                    <p className="orderscreen-p">
                      Invoice Number : {invoicedata?.fsdinvoicenumber}
                    </p>
                    {/* <p className="orderscreen-p">
                      FSD Amendment : {invoicedata?.fsdamendment}
                    </p> */}
                    <div
                      className=""
                      style={{ display: "flex", flexDirection: "row", marginBottom: 30 }}
                    >
                      <p className="orderscreen-p">AWB File : </p>
                      {
                        invoicedata?.fsdawbdoc ?
                        <div className="pdfrequestdiv">
                        <img
                          src={pdf}
                          style={{ width: 32, height: 32, marginLeft: 5 }}
                        />
                        <Link
                          target="_blank"
                          to={JSON.parse(invoicedata?.fsdawbdoc)?.link}
                          style={{ textDecoration: "none" }}
                        >
                          <p
                            style={{
                              color: "#000",
                              fontSize: 22,
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: 0,
                            }}
                          >
                            {JSON.parse(invoicedata?.fsdawbdoc)?.name}
                          </p>
                        </Link>
                      </div> : <div className="pdfrequestdiv">
                          <p
                            style={{
                              color: "#000",
                              fontSize: 22,
                              fontWeight: "bold",
                              marginLeft: 10,
                              marginTop: -5,
                            }}
                          >
                            Null
                          </p>
                            
                          </div>
                      }
                      
                    </div>

                    
                  </div>
                )
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
}

export default CustomerOrderTracking